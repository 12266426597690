<div class="row no-gutters date res">
  <div class="labl">
    <div class="col">
      <label class="form-label" required>{{ name }}</label>
      <div class="col-1 dk">
        <fa-icon
          *ngIf="helpText"
          (click)="showHelpText()"
          [icon]="faQuestionCircle"
          class="helpIcon fa-lg"
        ></fa-icon>
      </div>
    </div>
    <div class="w-100"></div>
    <div class="col-1 mb">
      <fa-icon
        *ngIf="helpText"
        (click)="showHelpText()"
        [icon]="faQuestionCircle"
        class="helpIcon fa-lg"
      ></fa-icon>
    </div>
  </div>  
  <br>
  <div class="date_grd">
    <div class="dts">
      <select
        class="form-control"
        #month
        (change)="formFieldChanged($event)"
        [disabled]="isDisabled"
      >
        <option
          *ngFor="let m of MONTHS_OF_YEAR"
          [value]="m.id"
          [selected]="m.id === dateMonth"
        >
          {{ m.name }}
        </option>
      </select>
    </div>
  
  <div class="dts">
    <input
      type="number"
      class="form-control"
      placeholder="dd"
      #day
      (change)="formFieldChanged($event)"
      [value]="dateDay"
      positiveNumber
      [disabled]="isDisabled"
    />
  </div>
  <div class="dts">
    <input
      type="number"
      class="form-control"
      placeholder="yyyy"
      #year
      (change)="formFieldChanged($event)"
      [value]="dateYear"
      positiveNumber
      [disabled]="isDisabled"
    />
  </div>
</div>
  <div class="w-100"></div>
  <div class="col offset-1 abs_l" *ngIf="hasValidationErrors">
    <small class="error" *ngIf="isInvalidDate">This is an Invalid Date.</small>
    <small class="error" *ngIf="hasFutureDateError"
      >You cannot specify a future date.</small
    >
    <small class="error" *ngIf="hasPastDateError"
      >You cannot specify a past date.</small
    >
    <small class="error" *ngIf="hasBeforeDateError"
      >This date cannot come before
      <strong>{{ comesAfterDate | date : "mediumDate" }}</strong
      >.</small
    >
    <small class="error" *ngIf="hasAfterDateError"
      >This date cannot come after
      <strong>{{ comesBeforeDate | date : "mediumDate" }}</strong
      >.</small
    >
  </div>
  <ng-template #helpTextModal let-modal *ngIf="helpText">
    <div class="modal-header">
      <h4 class="modal-title">{{ name }}</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div [innerHtml]="safeHtml(helpText)"></div>
    </div>
    <div class="modal-footer">
      <button
        ngbAutofocus
        type="button"
        class="btn btn-modal btn-lg"
        (click)="modal.close('Close click')"
      >
        Close
      </button>
    </div>
  </ng-template>
</div>
