import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared';
import { fromMetadata, MetadataEffects } from './state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([MetadataEffects]),
    StoreModule.forFeature(fromMetadata.metadataFeatureKey, fromMetadata.reducer)
  ]
})

export class MetadataModule { }