interface Location {
  getClientDomain(): string
  setClientDomain(domain: string): void
}

Location.prototype.getClientDomain = function () {
  var domain = ''
  if (window.location.pathname.indexOf('/f/') >= 0) {
    domain = window.location.pathname.split('/')[2]
    window.location.setClientDomain(domain)
  } else if (
    window.location.hash.length > 0 &&
    window.location.hash.indexOf('state=') < 0
  ) {
    domain = window.location.hash.substring(1)
    window.location.setClientDomain(domain)
  } else {
    domain = localStorage.getItem('fundKey')
    if (!domain) {
      domain = ''
    }
  }

  return domain
}

Location.prototype.setClientDomain = function (domain: string) {
  localStorage.setItem('fundKey', domain)
}
