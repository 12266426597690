<psrs-detail-display [route]="route" [label]="label" *ngIf="cnt">
  <div class="row proHeader">
    <div class="dest_img" *ngIf="cnt.image">
      <img [src]="cnt.image.uri" title="{{ cnt.primary }}" *ngIf="cnt.image" />

      <img
        src="./assets/images/user.png"
        *ngIf="!cnt.image"
        title="{{ cnt.primary }}"
      />
    </div>
    <p>
      <strong>{{ cnt.primary }}</strong
      >, <em>{{ cnt.secondary }}</em>
    </p>
    <p class="company">{{ cnt.tertiary }}</p>
  </div>
  <div
    class="proBody"
    [innerHtml]="safeHtml(cnt.details)"
    *ngIf="cnt.details"
  ></div>
</psrs-detail-display>
