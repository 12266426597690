import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LoggingService } from 'src/app/shared';
import { CalculatorFieldBaseComponent } from '../calculator-field-base';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'psrs-calc-date-field',
  templateUrl: './calc-date-field.component.html',
  styleUrls: ['./calc-date-field.component.scss']
})
export class CalcDateFieldComponent extends CalculatorFieldBaseComponent implements OnInit {

  @ViewChild("month") monthInput: TemplateRef<HTMLSelectElement>;
  @ViewChild("day") dayInput: TemplateRef<HTMLInputElement>;
  @ViewChild("year") yearInput: TemplateRef<HTMLInputElement>;

  @Input() onlyPastDates: Boolean = false;
  @Input() onlyFutureDates: Boolean = false;
  @Input() comesBeforeDate: Date = null;
  @Input() comesAfterDate: Date = null;
  @Input() isDisabled: Boolean = false;
  MONTHS_OF_YEAR = [
    { id: 0, name: 'Jan' },
    { id: 1, name: 'Feb' },
    { id: 2, name: 'Mar' },
    { id: 3, name: 'Apr' },
    { id: 4, name: 'May' },
    { id: 5, name: 'Jun' },
    { id: 6, name: 'Jul' },
    { id: 7, name: 'Aug' },
    { id: 8, name: 'Sept' },
    { id: 9, name: 'Oct' },
    { id: 10, name: 'Nov' },
    { id: 11, name: 'Dec' }
  ];  

  isInvalidDate: Boolean = false;
  isFutureDate: Boolean = false;
  isPastDate: Boolean = false;
  isBeforeDate: Boolean = false;
  isAfterDate: Boolean = false;
  dateMonth: Number;
  dateDay: Number;
  dateYear: Number;

  get hasValidationErrors(): Boolean {
    return this.isInvalidDate 
      || this.hasFutureDateError
      || this.hasPastDateError
      || this.hasBeforeDateError
      || this.hasAfterDateError;
  }

  get hasFutureDateError(): Boolean {
    return (this.onlyPastDates && this.isFutureDate);
  }

  get hasPastDateError(): Boolean {
    return (this.onlyFutureDates && this.isPastDate);
  }

  get hasBeforeDateError(): Boolean {
    return (this.comesAfterDate && this.isBeforeDate)
      && !(this.hasFutureDateError || this.hasPastDateError);
  }

  get hasAfterDateError(): Boolean {
    return (this.comesBeforeDate && this.isAfterDate)
      && !(this.hasFutureDateError || this.hasPastDateError);
  }

  constructor(private _loggingService:LoggingService, private _modalService:NgbModal, private sanitizer:DomSanitizer) { 
    super(_loggingService, _modalService)
  }

  ngOnInit(): void {
    if(!this.defaultValue) {
      return;
    }
    let defaultDate:Date = new Date(this.defaultValue);
    this.dateYear = defaultDate.getFullYear();
    this.dateMonth = defaultDate.getMonth();
    this.dateDay = defaultDate.getDate();

    setTimeout(() => {
      this.formFieldChanged(null);
    }, 500);    
  }

  formFieldChanged(evt): void {
    let month = this.monthInput['nativeElement'].value;
    let day = this.dayInput['nativeElement'].value;
    let year = this.yearInput['nativeElement'].value;
    
    if(Number(day) > 0 && Number(year) > 0) {
      let specifiedMoment: moment.Moment = moment([year, month, day]);
      
      this.isInvalidDate = !specifiedMoment.isValid() || year > 9999;
      this.isFutureDate = specifiedMoment.isAfter(moment());
      this.isPastDate = specifiedMoment.isBefore(moment());

      if(this.comesAfterDate) {
        this.isBeforeDate = specifiedMoment.isBefore(moment(this.comesAfterDate));
      }

      if(this.comesBeforeDate) {
        this.isAfterDate = specifiedMoment.isAfter(moment(this.comesBeforeDate));
      }
      
      if(evt !== true){
        let result = specifiedMoment.format('YYYY-MM-DDT00:00:00');
        this.publishChangedValue(result);
      }
      
      this.publishChangedStatus(!this.hasValidationErrors);
    } else {
      this.isInvalidDate = true;
      this.isFutureDate = false;
      this.isPastDate = false;      
      this.isAfterDate = false;
      this.isBeforeDate = false;
      this.publishChangedStatus(false);
    }
  }

  safeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}