import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as ClientApiActions from './client.actions';
import { ClientService } from '../services/client-service';
import { MemberDataActions } from 'src/app/member';
import { FileService } from 'src/app/shared/services/file-service';

@Injectable()
export class ClientEffects {

  constructor(private actions$: Actions, private clientService: ClientService, private fileService: FileService) { }

  loadClient$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(ClientApiActions.loadClient),
      mergeMap(action =>
        this.clientService.getClient(action.id).pipe(
          map(client => ClientApiActions.loadClientSuccess({ client })),
          catchError(error => of(ClientApiActions.loadClientFailure({ id: action.id, error }))))
        )
    );
  });

  loadMetadata$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(ClientApiActions.loadMetadata),
      mergeMap(action =>
        this.clientService.getMetadata(action.id).pipe(
          map(metadata => ClientApiActions.loadMetadataSuccess({ metadata })),
          catchError(error => of(ClientApiActions.loadMetadataFailure({ id: action.id, error }))))
        )
    );
  });

  loadClientWelcome$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(ClientApiActions.loadWelcomeText),
      mergeMap(action =>
        this.clientService.getWelcomeText(action.id).pipe(
          map(welcomeText => ClientApiActions.loadWelcomeTextSuccess({ welcomeText })),
          catchError(error => of(ClientApiActions.loadWelcomeTextFailure({ id: action.id, error }))))
        )
    );
  });  

  loadAssetGrowth$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(ClientApiActions.loadAssetGrowth),
      mergeMap(action =>
        this.clientService.getAssetGrowth(action.id).pipe(
          map(assetGrowth => ClientApiActions.loadAssetGrowthSuccess({ assetGrowth })),
          catchError(error => of(ClientApiActions.loadAssetGrowthFailure({ id: action.id, error }))))
        )
    );
  });

  loadProfessionals$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(ClientApiActions.loadProfessionals),
      mergeMap(action =>
        this.clientService.getProfessionals(action.id).pipe(
          map(professionals => ClientApiActions.loadProfessionalsSuccess({ professionals })),
          catchError(error => of(ClientApiActions.loadProfessionalsFailure({ id: action.id, error }))))
        )
    );
  });

  loadFaqs$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(ClientApiActions.loadFaqs),
      mergeMap(action =>
        this.clientService.getFAQs(action.id).pipe(
          map(faqs => ClientApiActions.loadFaqsSuccess({ faqs })),
          catchError(error => of(ClientApiActions.loadFaqsFailure({ id: action.id, error })))
        )
      )
    );
  });

  loadDocuments$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(ClientApiActions.loadDocuments),

      mergeMap(action =>
        this.clientService.getDocuments(action.id).pipe(
          map(documents => ClientApiActions.loadDocumentsSuccess({ documents })),
          catchError(error => of(ClientApiActions.loadDocumentsFailure({ id: action.id, error })))
        )
      )
    );
  });

  signFile$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(ClientApiActions.signFile),
      mergeMap(action =>
        this.fileService.signFile(action.fileKey).pipe(
          map(fileSig => ClientApiActions.signFileSuccess({ fileKey: action.fileKey, signature: fileSig, docType: action.docType })),
          catchError(error => of(ClientApiActions.signFileFailure({ fileKey: action.fileKey, error })))
        )
      )
    );
  });

}