<ngb-toast
*ngFor="let toast of toastService.toasts"
[header]="toast.headertext"
[class]="toast.classname"
[autohide]="toast.autohide"
[delay]="toast.delay || 5000"
(hide)="toastService.remove(toast)"
>
<ng-template [ngIf]="toast.isHtml" [ngIfElse]="text">
  <span class="closeToast" (click)="toastService.remove(toast)">x</span>
  <div [innerHtml]="toast.textOrTpl"></div>
</ng-template>

<ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>