export enum FundType {
  Normal = 0,
  BVFF = 1,
  ChicagoParks = 2,
  MobileAL = 3,
  Miramar = 4,
  ChicagoLabor = 5,
  ChicagoMinu = 6,
  WHP=7,
  WHG=8,
  WHF=9,
  Largo=10,
}
