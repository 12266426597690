import { Component, HostListener, OnInit } from '@angular/core'
import { faLock, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { AuthService, LoggingService, ToastService } from 'src/app/shared'
import { BroadcastService } from '@azure/msal-angular'
import { Router, RouterOutlet, ActivationEnd } from '@angular/router'
import { environment } from 'src/environments/environment'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { Platform } from '@angular/cdk/platform'
import { Title } from '@angular/platform-browser'
import { routeTransitionAnimation } from '../../app.transitions'
import { filter, takeUntil } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { MemberDataActions, MemberSelectors } from 'src/app/member'
import {
  ClientDataActions,
  ClientModels,
  ClientSelectors
} from 'src/app/client'
import { AppDataActions, AppSelectors } from '../../state'
import { Auth0Service } from '../../../shared/services/auth/auth0.service'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { HeaderComponent } from '../header/header.component'
import { PagesService } from 'src/app/pages/services/pages.service'
import { MemberService } from 'src/app/member/services/member-service'
import { OptInType } from 'src/app/member/models/member-opt-in'
import { fromMember } from 'src/app/member/state/index'
import { MemberDataState } from 'src/app/member/state/member.state'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimation]
})
export class AppComponent implements OnInit {
  title: string = ''
  logoUrl: string = ''
  username: string = ''
  impersonator: string = ''
  isLoggedIn: boolean = false
  falock: IconDefinition = faLock
  notifications_active = 'notifications_active'
  notifications_off = 'notifications_off'
  showIconActive: boolean = true
  subs: Subscription = new Subscription()
  isPensionAppOn: boolean = false
  dataOptIn: any = []
  unsubscribe$ = new Subject<void>()

  clientObj: ClientModels.Client

  constructor (
    private store: Store,
    private authService: Auth0Service,
    private router: Router,
    private loggingService: LoggingService,
    private titleService: Title,
    private toastService: ToastService,
    private platform: Platform,
    public dialog: MatDialog,
    private pagesService: PagesService,
    private memberService: MemberService
  ) {}

  ngOnInit (): void {
    this.checkScreenWidth()
    /************************
     * [START] This block of code needs to start outside of any authentication checks, so that it is "publicly" avaiable
     *************************/
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      //unregister any existing service workers
      for(let registration of registrations) {
          registration.unregister();
      } 
    });

    this.routeSpecificClients();
    this.writeInHeaderLinks();

    this.subs.add(
      this.store.pipe(select(ClientSelectors.getClient), filter(client => client != undefined)).subscribe(client => {
        this.title = client.name;
        this.clientObj = client;
      })
    );
    /************************
     * [END] This block of code needs to start outside of any authentication checks, so that it is "publicly" avaiable
     *************************/
    this.authService.initializeAuth()
    this.authService.isAuthenticated$.subscribe(el => {
      this.isLoggedIn = el

      if (el) {
        this.logoUrl = environment.clientMediaPath + '/icons/logo_64x64.png'

        this.subs.add(
          this.store
            .pipe(
              select(MemberSelectors.getMember),
              filter(member => member != undefined)
            )
            .subscribe(member => this.loggingService.setUserId(member.id))
        )

        this.subs.add(
          this.store
            .pipe(
              select(AppSelectors.getUserName),
              filter(un => un != undefined)
            )
            .subscribe(un => (this.username = un))
        )

        this.subs.add(
          this.store
            .pipe(
              select(AppSelectors.getImpersonation),
              filter(imp => imp != undefined)
            )
            .subscribe(imp => (this.impersonator = imp.name))
        )

        this.subs.add(
          this.router.events
            .pipe(filter(event => event instanceof ActivationEnd))
            .subscribe((event: ActivationEnd) => {
              var clientName = this.clientObj?.name ?? 'Loading...'
              if (event.snapshot?.data?.title) {
                this.loggingService.logPageView(
                  event.snapshot.data.title,
                  `/${event.snapshot.routeConfig.path}`
                )
                this.titleService.setTitle(
                  `${event.snapshot.data.title} - ${clientName}`
                )
              }
            })
        )

        var clientDomain = window.location.getClientDomain()
        this.writeInHeaderLinks()

        //Fetch data from local storage.
        var clientId = localStorage.getItem('municipalId') || ''
        var memberId = localStorage.getItem('memberId') || ''

        var sub = localStorage.getItem('sub') as string
        var directoryId = sub !== null ? sub.substr(sub.indexOf('|') + 1) : null
        var userName = localStorage.getItem('memberName')
        var impersonationId = localStorage.getItem('impersonationId') || ''
        var impersonationName = localStorage.getItem('impersonationName') || ''

        this.subs.add(
          this.store
            .pipe(
              select(MemberSelectors.getMember),
              filter(m => m == undefined)
            )
            .subscribe(() => {
              this.store.dispatch(
                MemberDataActions.loadMember({ id: memberId })
              )
            })
        )

        if (impersonationId && impersonationName) {
          this.subs.add(
            this.store
              .pipe(
                select(AppSelectors.getImpersonation),
                filter(imp => imp?.id != impersonationId)
              )
              .subscribe(() => {
                this.store.dispatch(
                  AppDataActions.setImpersonation({
                    id: impersonationId,
                    name: impersonationName
                  })
                )
              })
          )
        }

        this.subs.add(
          this.store
            .pipe(
              select(AppSelectors.getDirectoryId),
              filter(dir => dir != directoryId)
            )
            .subscribe(() => {
              this.store.dispatch(
                AppDataActions.setDirectoryId({ directoryId })
              )
            })
        )

        this.subs.add(
          this.store
            .pipe(
              select(AppSelectors.getUserName),
              filter(un => un != userName)
            )
            .subscribe(() => {
              this.store.dispatch(AppDataActions.setUserName({ userName }))
            })
        )

        this.subs.add(
          this.store
            .pipe(
              select(ClientSelectors.getClient),
              filter(c => c == undefined)
            )
            .subscribe(() => {
              this.store.dispatch(
                ClientDataActions.loadClient({ id: clientId })
              )
            })
        )

        this.subs.add(
          this.store
            .pipe(
              select(MemberSelectors.getMember),
              filter(m => m == undefined)
            )
            .subscribe(() => {
              this.store.dispatch(
                MemberDataActions.loadMember({ id: memberId })
              )
            })
        )

        if (this.isLoggedIn) {
          //make sure to send the user to the Splash page so everything can be loaded
          this.router.navigate([''], {
            fragment: window.location.getClientDomain()
          })
        }
        this.subs.add(
          this.store
            .pipe(
              select(MemberSelectors.getOptInMessage),
              filter(m => m == undefined)
            )
            .subscribe(() => {
              this.store.dispatch(
                MemberDataActions.loadOptInMessage({ clientId, memberId })
              )
            })
        )
        this.loadData()
      }
      // else{
      //   this.router.navigate([""]);
      // }
    })
  }

  isRouteAnimationEnabled = true

  @HostListener('window:resize', ['$event'])
  onResize (event: any) {
    this.checkScreenWidth()
  }

  checkScreenWidth () {
    this.isRouteAnimationEnabled = window.innerWidth <= 766
  }

  loadData () {
    this.subs.add(
      this.store
        .pipe(
          select(MemberSelectors.getOptInMessage),
          filter(pg => pg != undefined)
        )
        .subscribe(result => {
          this.isPensionAppOn =
            result?.memberNotificationOptIns.filter(x => x.isUseInPensionApp)
              ?.length > 0
          const smsData = result?.memberNotificationOptIns.filter(
            x => x.type === OptInType.SmsMms
          )
          this.showIconActive = smsData.length > 0 ? smsData[0].isOptIn : true
          this.dataOptIn = result.memberNotificationOptIns
        })
    )
  }

  ngOnDestroy () {
    this.subs.unsubscribe()
  }

  logout () {
    this.loggingService.logEvent('User Signed Out')
    this.authService.logout()
    sessionStorage.clear()

    return false
  }

  prepareRoute (outlet: RouterOutlet) {
    let rs =
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData.animationState
    if (rs === 'ContentScreen') {
      rs += `-${outlet.activatedRoute.snapshot.params.id}`
    }
    return rs
  }

  writeInHeaderLinks (): void {
    let clientKey = window.location.getClientDomain()
    let mediaPath = environment.clientMediaPath
    let head = document.getElementsByTagName('head')[0] as HTMLHeadElement
    let aHTML = new Array()

    if (clientKey && head.innerHTML.indexOf('icons/favicon.ico') < 0) {
      aHTML.push(
        '<link rel="apple-touch-icon" sizes="180x180" href="' +
          mediaPath +
          '/icons/apple-touch-icon.png">',
        '<link rel="icon" type="image/png" sizes="32x32" href="' +
          mediaPath +
          '/icons/favicon-32x32.png">',
        '<link rel="icon" type="image/png" sizes="16x16" href="' +
          mediaPath +
          '/icons/favicon-16x16.png">',
        '<link rel="shortcut icon" href="' + mediaPath + '/icons/favicon.ico">'
      );
      head.innerHTML += aHTML.join('\n');
    }
  }

  routeSpecificClients(): void {
    var clientKey = window.location.getClientDomain();
    var isPassthroughPath = window.location.pathname.indexOf('/f/') >= 0
      || window.location.href.indexOf('#') >= 0
      || window.location.search.indexOf('?v=') >= 0;

    if (clientKey || isPassthroughPath) {
      return
    }

    var domain = window.location.hostname.split('.')[0].toLowerCase()
    var nonProductionDomains = [
      'localhost',
      'ptgportal-app-dev',
      'ptgportal-app-uat',
      'ptgportal-app'
    ]

    if (nonProductionDomains.includes(domain)) {
      window.location.href = `${window.location.origin}/f/demo`
      return
    }

    window.location.href = `https://ptgportal.app/f/${domain}`
  }

  clickBell () {
    const clientId = localStorage.getItem('municipalId')
    const memberId = localStorage.getItem('memberId')

    const dialogRef = this.dialog.open(HeaderComponent, {
      autoFocus: false,
      height: 'auto',
      minWidth: '100%',
      data: {
        dataOptIn: this.dataOptIn,
        memberId: memberId,
        clientId: clientId
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(
        MemberDataActions.loadOptInMessage({ clientId, memberId })
      )
    })
  }
}
