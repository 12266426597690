import { createReducer, on } from '@ngrx/store';
import * as AppActions from './app.actions';
import { AppState } from './app.state';

export const appFeatureKey = 'app';
export const initialState:AppState = { 
  accessToken: '',
  directoryId: '',
  userName: ''
};

export const reducer = createReducer<AppState>(initialState,

  on(AppActions.setDirectoryId, (state, action) => {
    return {
      ...state,
      directoryId: action.directoryId
    };
  }),
  on(AppActions.setAccessToken, (state, action) => {
    return {
      ...state,
      accessToken: action.token
    };
  }),
  on(AppActions.setUserName, (state, action) => {
    return {
      ...state,
      userName: action.userName
    };
  }),
  on(AppActions.setImpersonation, (state, action) => {
    return {
      ...state,
      impersonation: {
        id: action.id,
        name: action.name
      }
    };
  })
);