import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { ClientSelectors } from 'src/app/client';
import { PagesModels, PagesSelectors } from 'src/app/pages';
import { TEMPLATE_NAME } from 'src/app/pages/models';
import { LoggingService } from 'src/app/shared';

@Component({
  selector: 'psrs-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent implements OnInit {
  page: PagesModels.PageDefinition;
  TEMPLATE_NAME = TEMPLATE_NAME;

  constructor(private store:Store, private route:ActivatedRoute, private titleService:Title, private loggingService:LoggingService) { }

  ngOnInit(): void {
    combineLatest([
      this.store.select(ClientSelectors.getClient),
      this.route.params
    ]).subscribe(([cl, prms]) => {
      this.store.select(PagesSelectors.getPage, { id: prms.id }).subscribe(pg => {
        this.titleService.setTitle(`${pg.header} - ${cl.name}`);
        this.loggingService.logPageView(pg.header);
        this.page = pg;
      })
    });
  }

}
