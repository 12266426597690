import { Input, Component, OnInit, EventEmitter, TemplateRef, ViewChild, Output } from "@angular/core";
import { IconDefinition, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoggingService } from "src/app/shared";

@Component({
  template: ''
})

export class CalculatorFieldBaseComponent implements OnInit {
    
    @Input() key: string;
    @Input() name: string;
    @Input() helpText: string;
    @Input() calcName: string;
    @Input() defaultValue: any;

    @Output() valueChanged: EventEmitter<any> = new EventEmitter();
    @Output() statusChanged: EventEmitter<any> = new EventEmitter();
  
    @ViewChild("helpTextModal")
    helpTextModal: TemplateRef<any>;
  
    faQuestionCircle:IconDefinition = faQuestionCircle;
  
    constructor(private loggingService:LoggingService, private modalService:NgbModal) { }
  
    ngOnInit(): void {
    }
  
    showHelpText() {
      this.loggingService.logEvent(`User Viewed ${this.calcName} Help`, { 
        field: this.name
      });
      this.modalService.open(this.helpTextModal, { 
        scrollable: true, 
        centered: true,
        backdrop: 'static',
        keyboard: false
      });
    }
    
    publishChangedValue(value:any): void {
      this.valueChanged.emit({
        key: this.key,
        value
      });      
    }

    publishChangedStatus(isValid:Boolean): void {
      this.statusChanged.emit({
        key: this.key,
        isValid
      });      
    }    
}