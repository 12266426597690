import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PagesSelectors } from 'src/app/pages';

@Component({
  selector: 'psrs-drilldown-detail',
  templateUrl: './drilldown-detail.component.html',
  styleUrls: ['./drilldown-detail.component.scss']
})
export class DrilldownDetailComponent implements OnInit {
  label: string = '';
  route: any[] = [];
  cnt: any;

  constructor(private sanitized:DomSanitizer, private store:Store, private activatedRoute:ActivatedRoute, private router:Router) { 
    this.cnt = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    var pageId = this.activatedRoute.snapshot.params['id'];
    this.store.select(PagesSelectors.getPage, { id: pageId }).subscribe(pg => {
      this.label = pg.navigation;
      this.route = ['/pages', pg.id];
    });
  }

  safeHtml(html: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(html);
  }
}
