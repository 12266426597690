import { createReducer, on } from '@ngrx/store';
import { DocumentType } from 'src/app/shared/models';
import * as ClientApiActions from './client.actions';
import { ClientState } from './client.state';

export const clientFeatureKey = 'client';
export const initialState:ClientState = { };

export const reducer = createReducer<ClientState>(initialState,
  on(ClientApiActions.loadClientSuccess, (state, { client }) => { 
    var coreClient = {
      ...client,
      reformDate: new Date(client.reformDate)
    };
    return {
        ...state,
        core: coreClient
    };
  }),
  on(ClientApiActions.loadClientFailure, (state, { id, error }) => state),
  on(ClientApiActions.loadMetadataSuccess, (state, { metadata }) => { 
    return {
        ...state,
        metadata
    };
  }),
  on(ClientApiActions.loadMetadataFailure, (state, { id, error }) => state),  
  on(ClientApiActions.loadWelcomeTextSuccess, (state, { welcomeText }) => { 
    return {
        ...state,
        welcomeText: welcomeText
    };
  }),
  on(ClientApiActions.loadWelcomeTextFailure, (state, { id, error }) => state),
  on(ClientApiActions.loadAssetGrowthSuccess, (state, { assetGrowth }) => { 
    return {
        ...state,
        assetGrowth: assetGrowth
    };
  }),
  on(ClientApiActions.loadAssetGrowthFailure, (state, { id, error }) => state),  
  on(ClientApiActions.loadProfessionalsSuccess, (state, { professionals }) => { 
    return {
        ...state,
        professionals: professionals
    };
  }),
  on(ClientApiActions.loadProfessionalsFailure, (state, { id, error }) => state),
  on(ClientApiActions.loadFaqsSuccess, (state, { faqs }) => { 
    return {
        ...state,
        faqs: faqs
    };
  }),
  on(ClientApiActions.loadFaqsFailure, (state, { id, error }) => state),
  on(ClientApiActions.loadDocumentsSuccess, (state, { documents }) => { 
    return {
        ...state,
        documents
    };
  }),
  on(ClientApiActions.loadDocumentsFailure, (state, { id, error }) => state),
  on(ClientApiActions.signFileSuccess, (state, { fileKey, signature, docType }) => {
    switch(docType)
    {
      case DocumentType.AssetGrowth:
        return {
          ...state,
          assetGrowth: {
            ...state.assetGrowth,
            statement: {
              ...state.assetGrowth.statement,
              signature: signature
            }
          }
        };
      
      case DocumentType.Form:
      case DocumentType.SPD:
        var idx = state.documents.findIndex(ds => ds.key === fileKey);
        var doc = {
          ...state.documents[idx],
          signature: signature
        };
        var newDocs = [...state.documents];
        newDocs.splice(idx, 1);
        newDocs.push(doc);

        return {
          ...state,
          documents: newDocs
        };
    }
  })  
);