<div class="row no-gutters">
  <div class="labl">
    <div class="col"><label class="form-label" required>{{ name }}</label>
      <div class="col-1 dk"><fa-icon *ngIf="helpText" (click)="showHelpText();" [icon]="faQuestionCircle" class="helpIcon fa-lg"></fa-icon></div>
    
    </div>
    <div class="w-100"></div>
    <div class="col-1 mb"><fa-icon *ngIf="helpText" (click)="showHelpText();" [icon]="faQuestionCircle" class="helpIcon fa-lg"></fa-icon></div>
    
  </div>
  <div class="date_grd">
    <div class="dts" *ngIf="useYear">
      <div class="input-group mb-2">
        <input type="number" placeholder="year" class="form-control" (change)="formFieldChanged($event);" [disabled]="isDisabled" [value]="defaultValue?.years" #years positiveNumber>
        <div class="input-group-prepend" *ngIf="useMonth">
          <!-- <div class="input-group-text">y</div> -->
        </div>        
      </div>
    </div>
    <div class="dts" *ngIf="useMonth">
      <div class="input-group mb-2">
        <input type="number" placeholder="month" class="form-control" (change)="formFieldChanged($event);" [disabled]="isDisabled" [value]="defaultValue?.months" #months positiveNumber>
        <div class="input-group-prepend">
          <!-- <div class="input-group-text">m</div> -->
        </div>      
      </div>
    </div>
    <div class="dts" *ngIf="useDay">
      <div class="input-group mb-2">
        <input type="number" placeholder="day" class="form-control" (change)="formFieldChanged($event);" [disabled]="isDisabled" [value]="defaultValue?.days" #days positiveNumber>
        <div class="input-group-prepend">
          <!-- <div class="input-group-text">d</div> -->
        </div>
      </div>
    </div>   
    </div>  
    <div class="w-100"></div>
    <div class="col offset-1 abs_l" *ngIf="hasValidationErrors && (overwriteYOS == null || overwriteYOS)">
      <small class="error" *ngIf="negativeYears">You cannot have <em>negative</em> Years.</small>
      <small class="error" *ngIf="maxYears">You cannot specify more than 99 Years.</small>
      <small class="error" *ngIf="negativeMonths">You cannot have <em>negative</em> Months.</small>
      <small class="error" *ngIf="maxMonths">You cannot specify more than 11 Months.</small>
      <small class="error" *ngIf="negativeDays">You cannot have <em>negative</em> Days.</small>
      <small class="error" *ngIf="maxDays">You cannot specify more than 30 Days.</small>
    </div>
    <ng-template #helpTextModal let-modal *ngIf="helpText">
      <div class="modal-header">
        <h4 class="modal-title">{{ name }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div [innerHtml]="safeHtml(helpText)"></div>
      </div>
      <div class="modal-footer">
        <button ngbAutofocus type="button" class="btn btn-modal btn-lg" (click)="modal.close('Close click')">Close</button>
      </div>
    </ng-template>
  </div>