import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { LoggingService } from 'src/app/shared'
import { CalculatorFieldBaseComponent } from '../calculator-field-base'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'psrs-calc-conditional-age-field',
  templateUrl: './calc-conditional-age-field.component.html',
  styleUrls: ['./calc-conditional-age-field.component.scss']
})
export class CalcConditionalAgeFieldComponent
  extends CalculatorFieldBaseComponent
  implements OnInit
{
  @ViewChild('toggle') toggleInput: TemplateRef<HTMLSelectElement>
  @ViewChild('gender') genderInput: TemplateRef<HTMLInputElement>
  @ViewChild('age') ageInput: TemplateRef<HTMLInputElement>

  @Input() useGender: boolean = false

  get hasValidationErrors (): Boolean {
    return (
      this.hasBeneficiary &&
      ((this.useGender && !this.hasGender) || !this.hasAge)
    )
  }

  hasBeneficiary: Boolean = false
  hasGender: Boolean = false
  hasAge: Boolean = false

  constructor (
    private _loggingService: LoggingService,
    private _modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {
    super(_loggingService, _modalService)
  }

  ngOnInit (): void {


    if (this.defaultValue?.age) {
      this.hasBeneficiary = true
      this.hasAge = this.defaultValue.age > 0
      this.hasGender = this.defaultValue.gender.length > 0
    }

    setTimeout(() => {
      this.formFieldChanged(null)
    }, 500)
  }

  formFieldChanged (evt): void {
    let toggleValue = this.toggleInput['nativeElement'].value
    let age = this.ageInput['nativeElement']

    this.hasBeneficiary = toggleValue === 'Yes'

    if (!this.hasBeneficiary) {
      age.value = ''

      if (this.useGender) {
        this.genderInput['nativeElement'].value = ''
      }
    } else {
      let ageVal = Number(age.value)
      this.hasAge = ageVal > 0

      if (this.useGender) {
        let genderVal = this.genderInput['nativeElement'].value
        this.hasGender = genderVal.length > 0
      }
    }
 
    if (!this.hasValidationErrors) {
      if (this.hasBeneficiary) {
        this.publishChangedValue({
          age: Number(age.value),
          gender: this.useGender ? this.genderInput['nativeElement'].value : ''
        })
      } else {
        this.publishChangedValue(null)
      }
    }
    this.publishChangedStatus(!this.hasValidationErrors)
  }

  safeHtml (html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
}
