<div class="background">
    <label class="font-header">Opt-In Options</label>
    <div>
        <div *ngIf="isShowPaperLess" class="custom-switch">
            <input [checked]="formGroup.get('isPaperLess')?.value" type="checkbox" id="togglePaperLess" (click)="onChangePaperlessToggle($event)">
            <label class="label-message" for="togglePaperLess">{{ formGroup.get('paperLessContent')?.value }}</label>
        </div>
    </div>
    <div>
        <div *ngIf="isShowSMS" class="custom-switch">
            <input [checked]="formGroup.get('isSMS')?.value" type="checkbox" id="toggleSMS" (click)="onChangeSMSToggle($event)">
            <label class="label-message" for="toggleSMS">{{ formGroup.get('smsContent')?.value }}</label>
        </div>
    </div>
    <div>
        <button class="button-close" (click)="onCancelClick()"><span class="material-icons">
            close
            </span></button>
    </div>
</div>

