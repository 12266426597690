import { Injectable } from '@angular/core';
//import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthGuard as Auth0Guard } from '@auth0/auth0-angular';

@Injectable()
export class AuthGuard extends Auth0Guard {    
    // getDestinationUrl(path: string): string {
    //     return super.getDestinationUrl(path);
    // }
    // loginInteractively(url: string): Promise<boolean> {
    //     return super.loginInteractively(url);
    // }
    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    //     return super.canActivate(route, state);
    // }    
}