<!-- <div class="mainContent">
    <div class="row">
        <h1>{{ page.header }}</h1>
    </div>
    <ng-container *ngFor="let content of page.content">
        <ng-container [ngSwitch]="content.key">
            <div class="row genericCallbox" *ngSwitchCase="TEMPLATE_KEY.SupportContact">
                <callbox *ngIf="content.value?.length">{{ content.value }}</callbox>
            </div>            
            <div class="alignCenter" *ngSwitchCase="TEMPLATE_KEY.Image">
                <img [src]="content.value.uri" class="genericPhoto" />
            </div>
            <div class="ql-editor">
                <div class="row genericBody" *ngSwitchCase="TEMPLATE_KEY.Body" [innerHtml]="safeHtml(content.value)">
                </div>
            </div>
            <div class="row" *ngSwitchCase="TEMPLATE_KEY.Attachment">
                <button (click)="downloadPdf(content.value);" class="pdfButton"><fa-icon [icon]="faFileDownload" class="faIcon pdfIcon"></fa-icon>{{ buttonText }}</button>
            </div>
        </ng-container>
    </ng-container>
</div> -->

<!-- <div class="mainContent">
  <div class="row">
    <h1>{{ page.header }}</h1>
  </div>
  <ng-container *ngFor="let content of page.content">
    <ng-container [ngSwitch]="content.key">
      <div
        class="row genericCallbox"
        *ngSwitchCase="TEMPLATE_KEY.SupportContact"
      >
        <callbox *ngIf="content.value?.length">{{ content.value }}</callbox>
      </div>
      <div class="row" *ngSwitchCase="TEMPLATE_KEY.Image">
        <img [src]="content.value.uri" class="genericPhoto" />
      </div>
      <div class="ql-editor">
        <div
          class="row genericBody"
          *ngSwitchCase="TEMPLATE_KEY.Body"
          [innerHtml]="safeHtml(content.value)"
        ></div>
      </div>
      <div class="row" *ngSwitchCase="TEMPLATE_KEY.Attachment">
        <button (click)="downloadPdf(content.value)" class="pdfButton">
          <fa-icon [icon]="faFileDownload" class="faIcon pdfIcon"></fa-icon
          >{{ buttonText }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</div> -->

<div class="deskto_p">
  <div class="homes">
    <h1 style="background-image: url(./assets/images/home_bg.svg);">{{ page.header }}</h1>

    <ng-container *ngFor="let content of page.content">
      <ng-container [ngSwitch]="content.key">
        <div class="row imgs" *ngSwitchCase="TEMPLATE_KEY.Image">
          <div class="welcome_img">
          <img [src]="content.value.uri" />
        </div>
        </div>
        <p
          class="row genericBody"
          *ngSwitchCase="TEMPLATE_KEY.Body"
          [innerHtml]="safeHtml(content.value)"
        ></p>

        <div class="btns" *ngSwitchCase="TEMPLATE_KEY.Attachment">
          <a href="" (click)="downloadPdf(content.value)"><img src="./assets/images/download.svg" />Download</a>
        </div> 

        <!-- <div class="row btns" *ngSwitchCase="TEMPLATE_KEY.Attachment">
          <button (click)="downloadPdf(content.value)" class="pdfButton">
            <fa-icon [icon]="faFileDownload" class="faIcon pdfIcon"></fa-icon
            >{{ buttonText }}
          </button>
        </div> -->
      </ng-container>
    </ng-container>
  </div>
</div>
