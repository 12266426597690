import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as AppActions from './app.actions';



@Injectable()
export class AppEffects {

  constructor(private actions$: Actions) {}

}
