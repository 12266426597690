import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { IconDefinition, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { Store } from '@ngrx/store'
import { Subscription, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { CalculatorSelectors } from 'src/app/calculator'
import { EstimatedPension } from 'src/app/calculator/models'
import { CalculatedOption } from 'src/app/calculator/models/estimated-pension.data'
import { CalcUtilService } from 'src/app/calculator/services/calc-utils'
import { LoggingService } from 'src/app/shared'
import { Router } from '@angular/router'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { FundType } from 'src/app/client/types'
@Component({
  selector: 'psrs-retirement-calculator-result',
  templateUrl: './retirement-calculator-result.component.html',
  styleUrls: ['./retirement-calculator-result.component.scss']
})
export class RetirementCalculatorResultComponent implements OnInit {
  faInfoCircle: IconDefinition = faInfoCircle
  subs: Subscription = new Subscription()
  vmPensionEstimator$: Observable<any>
  estimatedPension: any
  estimationFailure: Boolean = false
  estimationErrors: string[] = []
  hasBeneficiary: Boolean = false
  footerHtml: SafeHtml
  displayKeys: any
  calcName: string
  calcTitle: string = 'Calculated Option Results'
  calcSalaryTitle: string = 'Annual Salary at retirement'
  buttonText: string
  buttonClass: string
  protectedServiceText: string

  constructor (
    private store: Store,
    private location: Location,
    private calcUtilService: CalcUtilService,
    private loggingService: LoggingService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    let fHtml = this.router.getCurrentNavigation().extras.state?.footerHtml
    if (fHtml) {
      this.footerHtml = this.sanitizer.bypassSecurityTrustHtml(fHtml)
    }
    this.displayKeys =
      this.router.getCurrentNavigation().extras.state?.displayKeys
    this.calcName =
      this.router.getCurrentNavigation().extras.state?.calcName ??
      'Pension Estimate'
  }

  ngDestroy (): void {
    this.subs.unsubscribe()
  }

  ngOnInit (): void {
    // console.log('testing*****************')

    this.estimationErrors = []
    this.estimatedPension = null
    this.estimationFailure = false
    this.subs.add(
      this.store
        .select(CalculatorSelectors.getCalculatorError)
        .pipe(filter(ep => ep != undefined))
        .subscribe(err => {
          this.estimationErrors = []
          this.estimatedPension = null
          this.estimationFailure = false
          if (err.status == 400) {
            for (let prop in err.error) {
              let key = prop.split('.')[0].toLocaleLowerCase()
              if (this.displayKeys[key]) {
                this.estimationErrors.push(
                  `<strong>${this.displayKeys[key]}</strong> ${err.error[prop]}.`
                )
              } else {
                let result = JSON.parse(err.error[prop])
                this.estimationErrors.push(result.errorMessages)
                this.estimatedPension = {
                  memberAge: result.memberAge,
                  salary: result.salary
                }
              }
            }
          } else {
            this.loggingService.logTrace('UNABLE TO ESTIMATE PENSION OPTIONS', {
              httpError: err
            })
            this.loggingService.logException(err, SeverityLevel.Critical)
            this.estimationFailure = true
          }
        })
    )
    this.subs.add(
      this.store
        .select(CalculatorSelectors.getEstimatedPension)
        .pipe(filter(ep => ep != undefined))
        .subscribe(ep => {
          this.estimationFailure = false
          this.estimationErrors = []
          this.estimatedPension = {
            ...ep,
            options: []
          }
          this.hasBeneficiary = ep.beneficiaryAge > 0
          for (var i = 0; i < ep.options.length; i++) {
            this.estimatedPension.options[i] = {
              ...ep.options[i]
            }
            this.estimatedPension.options[i]['isCollapsed'] = true
            this.estimatedPension.options[i]['formula'] =
              this.estimatedPension.options[i]['formatType'] == 'Percent'
                ? '/'
                : 'x'
          }
        })
    )

    let fundType = parseInt(localStorage.getItem('fundType'))
    if (fundType === FundType.ChicagoLabor) {
      this.buttonText = 'RECALCULATE'
      this.buttonClass = 'btn-recalculate'
      this.protectedServiceText = 'Years of Pension Service at retirement'
    } else {
      this.buttonText = 'Revise'
      this.buttonClass = 'btn-revise'
      this.protectedServiceText = 'Length of Service at retirement'
    }

    if (
      fundType === FundType.WHG ||
      fundType === FundType.WHP ||
      fundType === FundType.WHF ||
      fundType === FundType.ChicagoLabor
    ) {
      this.calcTitle = 'Calculated Estimated Result'
      if (fundType !== FundType.ChicagoLabor) {
        this.calcSalaryTitle = 'Projected Average Annual Salary'
      }
    } else if (fundType === FundType.Largo) {
      this.calcTitle = 'Calculated Option Result'
    }
  }

  onRevise (): void {
    localStorage.setItem('revised', 'true')
    this.location.back()
  }

  toggleOption (op: CalculatedOption): void {
    op['isCollapsed'] = !op['isCollapsed']
    this.loggingService.logEvent('User Toggled Calculated Option', {
      state: op['isCollapsed'] ? 'collapsed' : 'expanded',
      which: op.name
    })
  }

  safeHtml (html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
}
