import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggingService } from 'src/app/shared';
import { CalculatorFieldBaseComponent } from '../calculator-field-base';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'psrs-calc-time-period-field',
  templateUrl: './calc-time-period-field.component.html',
  styleUrls: ['./calc-time-period-field.component.scss']
})
export class CalcTimePeriodFieldComponent extends CalculatorFieldBaseComponent implements OnInit {

  @ViewChild("months") monthInput: TemplateRef<HTMLSelectElement>;
  @ViewChild("days") dayInput: TemplateRef<HTMLInputElement>;
  @ViewChild("years") yearInput: TemplateRef<HTMLInputElement>;  

  @Input() granulation: string;
  @Input() isDisabled: boolean;
  @Input() overwriteYOS: boolean;

  get hasValidationErrors(): Boolean {
    return this.negativeYears 
      || this.maxYears 
      || this.negativeMonths
      || this.negativeDays
      || this.maxMonths
      || this.maxDays;
  }

  negativeYears:Boolean = false;
  maxYears:Boolean = false;
  negativeMonths:Boolean = false;
  maxMonths:Boolean = false;
  negativeDays:Boolean = false;
  maxDays:Boolean = false;

  useYear:Boolean = true;
  useMonth:Boolean = false;
  useDay:Boolean = false;

  constructor(private _loggingService:LoggingService, private _modalService:NgbModal, private sanitizer:DomSanitizer) { 
    super(_loggingService, _modalService)
  }

  ngOnInit(): void {
    if(this.granulation.indexOf('Months') >= 0) {
      this.useMonth = true;
    }
    if(this.granulation.indexOf('Days') >= 0) {
      this.useDay = true;
    }
    if(this.defaultValue) {
      setTimeout(() => {
        this.formFieldChanged(null);
      }, 500);
    }
  }

  formFieldChanged(evt): void {
    let years:Number = Number(this.yearInput['nativeElement'].value);
    let months:Number = 0;
    let days:Number = 0;
    this.overwriteYOS = true;
    this.negativeYears = (years < 0);
    this.maxYears = (years > 99);
    this.yearInput['nativeElement'].value = years;

    if(this.useMonth) {
      months = Number(this.monthInput['nativeElement'].value);
      this.negativeMonths = (months < 0);
      this.maxMonths = (months > 11);
      this.monthInput['nativeElement'].value = months;
    }

    if(this.useDay) {
      days = Number(this.dayInput['nativeElement'].value);
      this.maxDays = (days > 30);
      this.negativeDays = (days < 0);
      this.dayInput['nativeElement'].value = days;
    }    
    
      this.publishChangedValue({
        years, months, days
      });
    
    this.publishChangedStatus(!this.hasValidationErrors);
  }

  safeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}