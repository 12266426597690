import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PagesSelectors } from 'src/app/pages';
import { SharedModels } from 'src/app/shared';

@Component({
  selector: 'psrs-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit {
  form:SharedModels.FileSnapshot;
  subs:Subscription = new Subscription();

  label: string = '';
  route: any[] = [];
  cnt: any;

  constructor(private store:Store, private activatedRoute:ActivatedRoute, private router:Router) { 
    this.cnt = this.router.getCurrentNavigation().extras.state;
  }

  ngOnDestroy() {    
    this.subs.unsubscribe();
  }  

  ngOnInit(): void {
    var pageId = this.activatedRoute.snapshot.params['id']; 
    this.subs.add(
      this.store.select(PagesSelectors.getPage, { id: pageId }).subscribe(pg => {
        this.label = pg.navigation;
        this.route = ['/pages', pg.id];
      })
    );
  }
}