<psrs-detail-display [route]="route" [label]="label">
  <div class="pdf-view-header">
    <h1 class="title">{{ cnt.label }}</h1>
    <button class="pdfButton" (click)="downloadPdf(cnt.uri)">
      <fa-icon [icon]="faFileDownload" class="faIcon pdfIcon"></fa-icon>
    </button>
  </div>
  <!-- <psrs-display-payroll-pdf [PdfFile]="cnt"></psrs-display-payroll-pdf> -->
  <psrs-inline-pdf [PdfFile]="cnt"> </psrs-inline-pdf>
</psrs-detail-display>
