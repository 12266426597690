import * as PagesDataActions from './pages.actions';
import * as PagesSelectors from './pages.selectors';
import * as fromPages from './pages.reducer';

export {
    PagesDataActions,
    PagesSelectors,
    fromPages
}

export { PagesEffects } from './pages.effects';
export { PagesState } from './pages.state';