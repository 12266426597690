import { Injectable, EventEmitter } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

@Injectable()
export class LoadingIndicatorService {
  
  onLoadingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();  
  
  private requests: HttpRequest<any>[] = [];
  
  onStarted(req: HttpRequest<any>): void {
    if(!req.url.includes("undefined")){
      this.requests.push(req);
      this.notify();
    }
  }  
  
  onFinished(req: HttpRequest<any>): void {    
    this.requests.forEach(element => {
      if(element.url === req.url)
        this.requests = this.requests.filter(r=> r.url != req.url);
    });    
    this.notify();
  } 
 
  private notify(): void {
    this.onLoadingChanged.emit(this.requests.length !== 0);
  }
  
}