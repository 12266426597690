import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'psrs-unspecified-fund',
  templateUrl: './unspecified-fund.component.html',
  styleUrls: ['./unspecified-fund.component.scss']
})
export class UnspecifiedFundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
