import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AttachmentListComponent } from 'src/app/pages/components/templates/attachment-list/attachment-list.component';
import { PagesEffects, fromPages } from './state';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared';
import { RouterModule } from '@angular/router';
import { pagesRoutes } from './pages.routes';
import { GenericComponent } from './components/templates/generic/generic.component';
import { PageContainerComponent } from './components/containers/page-container/page-container.component';
import { AccordionComponent } from './components/templates/accordion/accordion.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DocumentListComponent } from './components/templates/document-list/document-list.component';
import { MemberDetailComponent } from './components/templates/member-detail/member-detail.component';
import { DrilldownComponent } from './components/templates/drilldown/drilldown.component';
import { RetirementCalculatorComponent } from './components/templates/retirement-calculator/retirement-calculator.component';
import { DrilldownDetailComponent } from './components/templates/drilldown/drilldown-detail.component';
import { DocumentViewComponent } from './components/templates/document-list/document-view.component';
import { MemberPdfViewComponent } from './components/templates/member-detail/member-pdf-view.component';
import { CalcDateFieldComponent } from './components/templates/retirement-calculator/input-fields/calc-date-field/calc-date-field.component';
import { CalcCurrencyFieldComponent } from './components/templates/retirement-calculator/input-fields/calc-currency-field/calc-currency-field.component';
import { CalcTimePeriodFieldComponent } from './components/templates/retirement-calculator/input-fields/calc-time-period-field/calc-time-period-field.component';
import { CalcConditionalAgeFieldComponent } from './components/templates/retirement-calculator/input-fields/calc-conditional-age-field/calc-conditional-age-field.component';
import { CalcListFieldComponent } from './components/templates/retirement-calculator/input-fields/calc-list-field/calc-list-field.component';
import { RetirementCalculatorResultComponent } from './components/templates/retirement-calculator/retirement-calculator-result.component';
import { ConvertToDecimalPipe } from './components/templates/retirement-calculator/convert-to-decimal.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MaskedEyeComponent } from './components/templates/member-detail/masked-eye/masked-eye.component';
import { PositiveNumberDirective } from './components/templates/retirement-calculator/directive/positive-number.directive';
import { RetireePayrollHistoryComponent } from './components/templates/retiree-payroll-history/retiree-payroll-history.component';
import { RetireePayrollHistoryPdfViewComponent } from './components/templates/retiree-payroll-history/retiree-payroll-history-pdf-view.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DesktopPdfModalComponent } from './components/templates/document-list/desktop-pdf-modal/desktop-pdf-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DisplayPayrollPdfComponent } from './components/templates/retiree-payroll-history/display-payroll-pdf/display-payroll-pdf.component';

@NgModule({
  declarations: [
    PageContainerComponent,
    GenericComponent,
    AccordionComponent,
    DocumentListComponent,
    MemberDetailComponent,
    DrilldownComponent,
    RetirementCalculatorComponent,
    DrilldownDetailComponent,
    DocumentViewComponent,
    MemberPdfViewComponent,
    CalcDateFieldComponent,
    CalcCurrencyFieldComponent,
    CalcTimePeriodFieldComponent,
    CalcConditionalAgeFieldComponent,
    CalcListFieldComponent,
    RetirementCalculatorResultComponent,
    ConvertToDecimalPipe,
    MaskedEyeComponent,
    PositiveNumberDirective,
    RetireePayrollHistoryComponent,
    AttachmentListComponent,
    RetireePayrollHistoryPdfViewComponent,
    DesktopPdfModalComponent,
    DisplayPayrollPdfComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    PdfViewerModule,
    SharedModule,
    NgbModule,
    MatIconModule,
    MatProgressSpinnerModule,
    StoreModule.forFeature(fromPages.pagesFeatureKey, fromPages.pagesReducers),
    EffectsModule.forFeature([PagesEffects]),
    RouterModule.forChild(pagesRoutes),
  ],
})
export class PagesModule {}
