import { Route } from '@angular/compiler/src/core'
import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { Store } from '@ngrx/store'
import { ClientDataActions } from 'src/app/client'
import { PagesDataActions, PagesModels, PagesSelectors } from 'src/app/pages'
import { LoggingService } from 'src/app/shared'
import { DocumentSnapshot, FileSnapshot } from 'src/app/shared/models'
import { FileService } from 'src/app/shared/services/file-service'
import { SignatoryService } from 'src/app/shared/services/signatory-service'

@Component({
  selector: 'psrs-generic-template',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss']
})
export class GenericComponent implements OnInit {
  private _page: PagesModels.PageDefinition

  @Input() set page (value: PagesModels.PageDefinition) {
    this._page = value
    this.redrawComponent()
  }

  get page (): PagesModels.PageDefinition {
    return this._page
  }

  faFileDownload: IconDefinition = faFileDownload
  buttonText: string

  TEMPLATE_KEY: any = {
    SupportContact: 'supportContact',
    Image: 'image',
    Body: 'body',
    Attachment: 'attachment'
  }

  constructor (
    private sanitized: DomSanitizer,
    private loggingService: LoggingService,
    private signatoryService: SignatoryService,
    private store: Store
  ) {}

  ngOnInit (): void {}

  redrawComponent (): void {
    var attachmentContent = this.page.content.find(c => c.key === 'attachment')
    if (attachmentContent?.value != null) {
      var attachmentText = this.page.content.find(
        c => c.key === 'attachmentText'
      )
      this.buttonText = attachmentText.value

      this.signatoryService.getUrl(attachmentContent.value, fileKey =>
        this.store.dispatch(
          PagesDataActions.signAttachment({
            pageId: this.page.id,
            fileKey: fileKey
          })
        )
      )
    }
  }

  safeHtml (html: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(html)
  }

  downloadPdf (report: FileSnapshot): void {
    var downloadUrl = this.signatoryService.getUrl(report)
    if (downloadUrl) {
      this.loggingService.logEvent('User Downloaded Pdf')
      window.location.href = downloadUrl
    }
  }
}
