import { Component, OnInit, Input } from '@angular/core';
import { IconDefinition, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'psrs-detail-display',
  templateUrl: './detail-display.component.html',
  styleUrls: ['./detail-display.component.scss']
})
export class DetailDisplayComponent implements OnInit {

  faChevron:IconDefinition = faChevronLeft;

  constructor() { }

  @Input("label") backToLabel:string;
  @Input("route") backToRoute:any;

  ngOnInit(): void {
  }

}
