import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FundIdentifierComponent } from './components/fund-identifier/fund-identifier.component'
import { SplashComponent } from './components/splash/splash.component'
import { UnspecifiedFundComponent } from './components/unspecified-fund/unspecified-fund.component'
import { AuthGuard } from '@auth0/auth0-angular'
import { PromptFundComponent } from './components/prompt-fund/prompt-fund.component'

const routes: Routes = [
  {
    path: '',
    component: SplashComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Splash',
      animationState: 'SplashScreen'
    }
  },
  {
    path: 'unspecified',
    component: UnspecifiedFundComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Unspecified Fund Error'
    }
  },
  {
    path: 'prompt',
    component: PromptFundComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Prompt Fund Information'
    }
  },
  {
    path: 'f/:clientDomain',
    component: FundIdentifierComponent,
    data: {
      title: 'Fund Identifier'
    }
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
