<div id="splashPage">
    <div>
    <img [src]="logoUrl" />
    <p>Hello, <strong>{{ userName }}</strong>!</p>
    <p>{{ statusText }}</p>
    <div class="progress-bar" *ngIf="!isDataFetched">
        <ngb-progressbar 
            [value]="progressBarValue"
            height="40px"
            type="danger" 
            striped="true"
            animated="true"
            showValue="true">
        </ngb-progressbar>
    </div>
</div>
</div>