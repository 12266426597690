export class PersonName {
    prefix: string = '';
    first: string = '';
    middle: string = '';
    last: string = '';
    suffix: string = '';

    constructor(obj: any) {
        this.prefix = obj?.prefix;
        this.first = obj?.first ? obj?.first : '';
        this.middle = obj?.middle;
        this.last = obj?.last ? obj?.last : '';
        this.suffix = obj?.suffix;
    }
    
    public get full() : string {
        let result = '';
        if (this.prefix?.length) {
            result = `${this.prefix}. ${this.first}`;
        } else {
            result = `${this.first}`;
        }
        if (this.middle?.length) {
            result += ` ${this.middle}`;
        }        
        if (this.suffix?.length) {
            result += ` ${this.last}, ${this.suffix}`;
        } else {
            result += ` ${this.last}`;
        }
        return result;
    }
}