import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { retryWhen, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Metadata } from '../models';
import { timedRetryStrategy } from './timedRetryStrategy';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  retryStrategy:MonoTypeOperatorFunction<any> = retryWhen(timedRetryStrategy({
    timeoutDuration: environment.http.timeout,
    maxRetryAttempts: environment.http.retry,
    scalingDuration: 500
  }));

  constructor(private httpClient: HttpClient) { }

  public get(): Observable<Metadata[]> {
    return this.httpClient.get<Metadata[]>(`${environment.apiUrl}/Fund/Metadata`).pipe(this.retryStrategy).pipe(map(x => x.metadata));
  }      
}
