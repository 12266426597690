import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AppDataInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var impersonationId = localStorage.getItem('impersonationId') || '';
    var municipalId = localStorage.getItem('municipalId') || '';
    var memberId = localStorage.getItem('memberId') || '';
    var clientKey = window.location.getClientDomain();
    var req = request.clone({
      headers: request.headers
        .set('impersonationId', impersonationId)
        .set('municipalId', municipalId)
        .set('memberId', memberId)
        .set('client_domain', clientKey)
    })
    return next.handle(req);
  }
}