import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { LoggingService } from '../../services/logging/logging.service';
import { faEnvelopeSquare, faHeadset, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClientState } from 'src/app/client/state';
import { ClientSelectors } from 'src/app/client';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'callbox',
  templateUrl: './callbox.component.html',
  styleUrls: ['./callbox.component.scss']
})
export class CallboxComponent implements OnInit {
  faHeadset:IconDefinition = faHeadset;
  faEnvelopeSquare:IconDefinition = faEnvelopeSquare;
  
  subs:Subscription = new Subscription();
  faIcon:IconDefinition;
  displayValue: string;
  clickValue: string;
  
  @Input() phone:string;
  @Input() email:string;

  constructor(private clientStore:Store<ClientState>, private loggingService:LoggingService, private activatedRoute:ActivatedRoute) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }  

  initData(){
    if(this.phone) {
      this.faIcon = faHeadset;
      this.displayValue = this.phone
      this.clickValue = `tel:${this.phone}`;
    } else if(this.email) {
      this.faIcon = faEnvelopeSquare;
      this.displayValue = this.email
      this.clickValue = `mailto:${this.email}`;
    } else {

      this.subs.add(
        this.clientStore.select(ClientSelectors.getClient).pipe(filter(c => c.supportChannel != null)).subscribe(client => {
          if(client.supportChannel === 'Phone') {
            this.faIcon = faHeadset;
            this.displayValue = client.supportPhone;
            this.clickValue = `tel:${client.supportPhone}`;
          } else {
            this.faIcon = faEnvelopeSquare;
            this.displayValue = client.supportEmail;
            this.clickValue = `mailto:${client.supportEmail}`;
          }
        })
      );
    }   
  }

  ngOnInit(): void {
    this.initData();        
  }

  trackPress($event) {
    this.loggingService.logEvent('User Called Support', {
      PageTitle: this.activatedRoute.snapshot.data.title,
      PageUrl: `/${this.activatedRoute.snapshot.routeConfig.path}`
    });
  }
}