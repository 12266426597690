import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MemberService } from 'src/app/member/services/member-service';
import { PagesModels } from 'src/app/pages';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { ClientService } from 'src/app/client/services/client-service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { MemberDataActions, MemberSelectors } from 'src/app/member';
import { PaymentHistoryRequest } from 'src/app/member/models/payment-history-request';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FundType } from 'src/app/client/types';

@Component({
  selector: 'psrs-retiree-payroll-history',
  templateUrl: './retiree-payroll-history.component.html',
  styleUrls: ['./retiree-payroll-history.component.scss']
})
export class RetireePayrollHistoryComponent implements OnInit, OnDestroy {
  private _page: PagesModels.PageDefinition
  modalLabel: string | ''
  selectedPdfUrl: any
  unsubscribe$ = new Subject<void>()
  subs: Subscription = new Subscription()
  @Input() set page (value: PagesModels.PageDefinition) {
    this._page = value
  }

  get page (): PagesModels.PageDefinition {
    return this._page
  }
  isPdfModalOpen: Boolean = false

  allSelected = false
  faCoffee = faCoffee
  phone: string
  email: string
  showPdfPayroll: boolean = false
  buttonText: string
  isDisabled: boolean = true
  years: number[]
  defaultValue: any
  payments: any[]
  paymentTemps: any[]
  TEMPLATE_KEY: any = {
    SupportContact: 'supportContact',
    PayrollHistoryTable: 'payrollHistory'
  };
  loading = false;
  fundType: FundType;

  constructor(private memberService: MemberService,private clientService: ClientService,  private router:Router, private store:Store,) { }

  ngOnInit(): void {
    this.fundType = parseInt(localStorage.getItem('fundType'));
    this.getData();
  }

  getData(): void {

    this.clientService.getClient("")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data=>{
        if(data){
          this.email = data?.supportEmail;
          this.phone = data?.supportPhone;
        }
    });    

    if (this.fundType === FundType.ChicagoLabor) {
      this.store.select(MemberSelectors.getMetadataAll).subscribe(md=>{      
        this.payments = md[this.page.config.metadataKey].table.map((row)=> {return {
          'netPayment' : row[this.page.config.netPayment],
          'gross' : row[this.page.config.grossPayment],
          'depositDate' : row[this.page.config.paymentDate],
          'index': row['index']
        }});
  
        this.years = md[this.page.config.metadataKey].listYear;        
      });      
    }
    else {
      this.memberService.getMemberFixedPaymenthistories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data=>{
        if(data){
          this.years = data.years;
          this.payments = data.payments.map((p, index)=> {return {
            ...p,
            checked: false,
            index: index
          }});
        }
      });
    }

    this.paymentTemps = this.payments;
    this.defaultValue = this.years ? Math.max(...this.years): 'All';
    this.selectOnChange(this.defaultValue);    
  }

  selectOnChange (event: any) {
    this.allSelected = false
    this.isDisabled = true
    if (event === 'All') {
      this.payments = this.paymentTemps.map(p => {
        return {
          ...p,
          checked: false
        }
      })
    } else {
      this.payments = this.paymentTemps
        .filter(
          ep =>
            new Date(ep.depositDate).getFullYear().toString() ===
            event.toString()
        )
        .map(p => {
          return {
            ...p,
            checked: false
          }
        })
    }
  }

  onChange (event: number, isCheck: boolean) {
    if (event === -1) {
      this.payments = this.payments.map(p => {
        return {
          ...p,
          checked: !isCheck
        }
      })
      this.allSelected = !isCheck
      this.isDisabled = isCheck
    } else {
      this.payments[event].checked = !isCheck
      var first = this.payments.find(({ checked }) => checked === false)
      if (first === null || first === undefined || first.length === 0) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
      var selectedItem = this.payments.find(({ checked }) => checked === true)
      if (
        selectedItem === null ||
        selectedItem === undefined ||
        selectedItem.length === 0
      ) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    }
  }

  changeShowPdf (value) {
    this.isPdfModalOpen = value
  }
  closePdfModal () {
    this.isPdfModalOpen = false
  }
  downloadPdf (downloadUrl: any): void {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank')
    }
  }
  viewPaymentAdvice () {
    if (this.fundType === FundType.ChicagoLabor) {
      let index = this.payments.filter(x => x.checked).map(x => x.index)
      let req: PaymentHistoryRequest = {
        index: index,
        timeZone: DateTime.now().toFormat('ZZ')
      }

      this.store.dispatch(
        MemberDataActions.generatePaymentInfo({ paymentHistoryRequest: req })
      )
      this.subs.add(
        this.store
          .pipe(
            select(MemberSelectors.generatePaymentInfo),
            filter(data => data != undefined)
          )
          .subscribe(result => {
            this.changeShowPdf(true)
            this.selectedPdfUrl = result.uri
            this.modalLabel = result.fileName
            // this.router.navigate(['pages', this.page.id, 'payroll-history'], {
            //   state: {
            //     label: result.fileName,
            //     uri: result.uri
            //   },
            //   fragment: window.location.getClientDomain()
            // })
          })
      )
    }
  }

  ngOnDestroy (): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
