import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MonoTypeOperatorFunction, Observable } from "rxjs";
import { retry, timeout } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { FileSignature } from "../models/file-signature";

@Injectable({
    providedIn: 'root'
})
export class FileService {
    timeoutConfig:MonoTypeOperatorFunction<any> = timeout(environment.http.timeout);
    retryConfig:MonoTypeOperatorFunction<any> = retry(environment.http.retry);

    constructor(private httpClient: HttpClient) { }

    public signFile(fileKey: string): Observable<FileSignature> {
        return this.httpClient.get<FileSignature>(`${environment.apiUrl}/Files/Sign?fileKey=${fileKey}`).pipe(this.timeoutConfig, this.retryConfig);
    }    
}