<div class="pdf_container">
  <a class="bk" (click)="sendDataToParent()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.20076 1.05315C8.59128 1.44367 8.59128 2.07684 8.20076 2.46736L2.90787 7.76025L8.20076 13.0531C8.59128 13.4437 8.59128 14.0768 8.20076 14.4674C7.81023 14.8579 7.17707 14.8579 6.78654 14.4674L0.786545 8.46736C0.396021 8.07684 0.396021 7.44367 0.786545 7.05315L6.78655 1.05315C7.17707 0.662623 7.81023 0.662623 8.20076 1.05315Z"
        fill="black"
      />
    </svg>
    Back To Payment History</a
  >
  <h3>
    PDF FILE NAME<a href="">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="53"
        height="46"
        viewBox="0 0 53 46"
        fill="none"
      >
        <rect
          x="0.325195"
          y="0.866699"
          width="52.6689"
          height="45"
          rx="10"
          fill="#5A9EA5"
        />
        <path
          d="M27.5231 19.0486C28.3867 19.0486 28.3867 19.0486 28.3867 19.9123V23.3665H31.4092L26.6594 28.5481L21.9096 23.3665H24.9321V19.9121C24.9321 19.0484 24.9321 19.0484 25.7958 19.0484L27.5231 19.0486ZM34.4317 13.0034H18.8871C18.0234 13.0034 18.0234 13.0034 18.0234 13.8671V32.8662C18.0234 33.7298 18.0234 33.7298 18.8871 33.7298H30.1139L35.2954 28.5483V13.8671C35.2954 13.0034 35.2954 13.0034 34.4317 13.0034Z"
          fill="white"
        />
      </svg>
    </a>
  </h3>
  <div class="pdf_inner">
    <!-- https://stdemoedgedevcentralus.blob.core.windows.net/assets/fund01/pages/164ebfcb-03ea-44a8-a37b-cece818307c4/documents/New%20soft_Busines.pdf -->

    <div class="pdf-conntainer">
      <!-- <pdf-viewer
                [src]="pdfUrl"
                [render-text]="true"
                [autoresize]="true"
                [zoom]="2"
                [zoom-scale]="'page-width'"
                style="display: block; width: 100%; height: 100vh;"
              ></pdf-viewer> -->
      <pdf-viewer
        [src]="pdfUrl"
        [render-text]="true"
        [autoresize]="true"
        style="display: block; width: '{{ pdfWidth }}% !important'"
        [zoom]="zoomScale"
      ></pdf-viewer>
    </div>
    <!-- <div class="modal-buttons">
      <button>Close</button>
      <button class="dl">
        <img src="./assets/images/downl.png" />Download
      </button>
    </div> -->
  </div>
</div>
