import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as PageActions from './pages.actions';
import { PagesService } from '../services/pages.service';
import { FileService } from 'src/app/shared/services/file-service';

@Injectable()
export class PagesEffects {

  loadPages$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(PageActions.loadPages),
      mergeMap(action => 
        this.pagesService.getPages().pipe(
            map(pages => PageActions.loadPagesSuccess({ pages })),
            catchError(error => of(PageActions.loadPagesFailure({ error })))            
        )
      )      
    );
  });

  signAttachments$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(PageActions.signAttachment),
      mergeMap(action => 
        this.fileService.signFile(action.fileKey).pipe(
          map(fileSig => PageActions.signAttachmentSuccess({ pageId: action.pageId, fileKey: action.fileKey, signature: fileSig })),
          catchError(error => of(PageActions.signAttachmentFailure({ pageId: action.pageId, fileKey: action.fileKey, error })))          
        )
      )      
    );
  });   

  constructor(private actions$: Actions, private pagesService: PagesService, private fileService: FileService) { }
}