<!-- <div class="mainContent"> -->
    <div class="deskto_p">
        <div class="mob_pad">
    <div class="row">
        <h1>{{ page.header }}</h1>
    </div>
    <div class="row" *ngIf="contentList?.length">
        <ngb-accordion (panelChange)="panelChanged($event);">
            <ngb-panel [title]="cnt.title" *ngFor="let cnt of contentList">
                <ng-template ngbPanelContent>
                    <div class="accord" [innerHtml]="safeHtml(cnt.content)"></div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    </div>
</div>
