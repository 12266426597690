import { Component, Inject } from '@angular/core';
import { faBars, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { MemberService } from 'src/app/member/services/member-service';
import { OptInType, SetNotificationOptInOptOut } from 'src/app/member/models/member-opt-in';
import * as fromMember from 'src/app/member/state/member.reducer';
import { MemberDataActions, MemberSelectors } from 'src/app/member/state';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'psrs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  menuIcon: IconDefinition = faBars;
  subs: Subscription = new Subscription();
  formGroup: FormGroup;
  isPensionAppOn: boolean = false;
  isShowPaperLess: boolean = false;
  isShowSMS: boolean = false;
  
  constructor(
    private store:Store,
    public dialogRef: MatDialogRef<HeaderComponent>,
    private router: Router,
    private memberService: MemberService,
    @Inject(MAT_DIALOG_DATA,) public data: any) {
  }

  ngDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    const paperLessData = this.data.dataOptIn.length > 0 ? this.data.dataOptIn.find(x => x.type === OptInType.Paperless) : null;
    const smsData = this.data.dataOptIn.length > 0 ? this.data.dataOptIn.find(x => x.type === OptInType.SmsMms) : null;
    this.isShowPaperLess = paperLessData?.isUseInPensionApp;
    this.isShowSMS = smsData?.isUseInPensionApp;
    this.formGroup = new FormGroup({
      isPaperLess: new FormControl(paperLessData?.isOptIn),
      paperLessContent: new FormControl(paperLessData?.isUseInPensionApp ? paperLessData?.complianceMessage : null),
      isSMS: new FormControl(smsData?.isOptIn),
      smsContent: new FormControl(smsData?.isUseInPensionApp ? smsData?.complianceMessage : null),
    });
  }

  onCancelClick(): void {
    const toggle = document.getElementById("toggleSMS") as HTMLInputElement;
    this.dialogRef.close( {isSMS: toggle?.checked });
  }

  onChangePaperlessToggle(event: Event): void {
    const toggle = document.getElementById("togglePaperLess") as HTMLInputElement;
    const clientId = this.data?.clientId;
    const memberId = this.data?.memberId;
    const body: SetNotificationOptInOptOut = {
      clientId: clientId,
      optInType: OptInType.Paperless,
      isOptIn: toggle.checked
    }

    this.store.dispatch(MemberDataActions.setOptInMessage({ memberId, body }));
    if (!toggle.checked) {
      this.reloadMessage(memberId, clientId, false);
    }
  }

  onChangeSMSToggle(event?: Event): void {
    const toggle = document.getElementById("toggleSMS") as HTMLInputElement;
    const clientId = this.data?.clientId;
    const memberId = this.data?.memberId;
    const body: SetNotificationOptInOptOut = {
      clientId: clientId,
      optInType: OptInType.SmsMms,
      isOptIn: toggle.checked
    }

    this.store.dispatch(MemberDataActions.setOptInMessage({ memberId, body }));
    if (!toggle.checked) {
      this.reloadMessage(memberId, clientId, true);
    }
  }

  reloadMessage(memberId: string, clientId: string, isSMS: boolean): void {
    this.store.dispatch(MemberDataActions.loadOptInMessage({ clientId: clientId, memberId: memberId}));
    this.subs.add(
      this.store.pipe(select(MemberSelectors.getOptInMessage), filter(pg => pg != undefined)).subscribe(result => {
        const paperLessData = result?.memberNotificationOptIns.length > 0 ? result?.memberNotificationOptIns.find(x => x.type === OptInType.Paperless) : null;
        const smsData = result?.memberNotificationOptIns.length > 0 ? result?.memberNotificationOptIns.find(x => x.type === OptInType.SmsMms) : null;
        if (isSMS) {
          this.formGroup.get('smsContent').setValue(smsData?.complianceMessage);
        }
        if (!isSMS) {
          this.formGroup.get('paperLessContent').setValue(paperLessData?.complianceMessage);
        }
      })
    );
  }
}