<div class="row no-gutters resi">
  <div class="labl">
    <div class="col"><label class="form-label" required>{{ name }}</label>
      <div class="col-1 dk"><fa-icon *ngIf="helpText" (click)="showHelpText();" [icon]="faQuestionCircle" class="helpIcon fa-lg"></fa-icon></div>
  
    </div>
    <div class="w-100"></div>
    <div class="col-1 mb"><fa-icon *ngIf="helpText" (click)="showHelpText();" [icon]="faQuestionCircle" class="helpIcon fa-lg"></fa-icon></div>
  
  </div>
    <div class="col-6 fu_ll">
      <div class="input-group mb-2">
        <div class="input-group-prepend">
          <div class="input-group-text dol_l">$</div>
        </div>
        <input type="text" class="form-control dll" #salary (keyup)="formFieldChanged($event);" [value]="salaryDisplay" [disabled]="isDisabled" positiveNumber/>
      </div>
    </div>
    <div class="w-100"></div>
    <div class="col offset-1 abs_l" *ngIf="hasValidationErrors">
      <small class="error" *ngIf="hasValidationErrors">You must specify your {{ name }}.</small>
    </div>    
    <ng-template #helpTextModal let-modal *ngIf="helpText">
      <div class="modal-header">
        <h4 class="modal-title">{{ name }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div [innerHtml]="safeHtml(helpText)"></div>
      </div>
      <div class="modal-footer">
        <button ngbAutofocus type="button" class="btn btn-modal btn-lg" (click)="modal.close('Close click')">Close</button>
      </div>
    </ng-template>
</div>