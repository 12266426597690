<!-- <div class="row callbox" *ngIf="displayValue != null">
    <div class="col-4 paddingtop10"><fa-icon [icon]="faIcon" class="faIcon callIcon"></fa-icon></div>
    <div class="col-8 callText"><ng-content></ng-content>
        <a href="{{ clickValue }}" (click)="trackPress($event)"><div class="phoneNumber">{{ displayValue }}</div></a>
    </div>
</div> -->

<div class="header_banner_info" *ngIf="displayValue != null"
          style="background: url(./assets/images/info.png)"
        >
          <h2>
            <ng-content></ng-content>
            <div><a href="{{ clickValue }}" (click)="trackPress($event)">{{ displayValue }}</a></div>
          </h2>
        </div>