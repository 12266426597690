<div class="pdf_container">
  <div class="pdf_inner">
    <div class="pdf-conntainer inline_view">
      <pdf-viewer
        [src]="fileUri"
        [original-size]="false"
        [render-text]="true"
        [show-all]="false"
        [(page)]="page"
        (after-load-complete)="afterLoadComplete($event)"
        [render-text]="true"
        [autoresize]="true"
        style="display: block; width: '{{ pdfWidth }}% !important'"
        [zoom]="zoomScale"
        >Pdf ViewVer</pdf-viewer
      >
    </div>
  </div>
</div>
<!-- <ngb-pagination
  [collectionSize]="collectionSize"
  [pageSize]="10"
  [(page)]="page"
  [maxSize]="5"
  [rotate]="true"
  [boundaryLinks]="true"
  (pageChange)="pageChanged($event)"
>
</ngb-pagination> -->
