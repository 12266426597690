export interface MemberNotificationOptInsList {
    memberNotificationOptIns: MemberNotificationOptIns[]
}

export interface MemberNotificationOptIns {
    createdBy: string;
    dateCreated: string;
    updatedBy: string;
    dateUpdated: string;
    id: string;
    clientId: string;
    memberId: string;
    type: OptInType;
    complianceMessage: string;
    note: string;
    isOptIn: boolean;
    isUseInPensionApp: boolean;
}

export enum OptInType {
    Paperless,
    SmsMms
}

export interface SetNotificationOptInOptOut {
    clientId: string;
    optInType: OptInType;
    isOptIn: boolean;
}