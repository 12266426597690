import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { fromClient, ClientEffects } from './state';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    SharedModule,
    EffectsModule.forFeature([ClientEffects]),
    StoreModule.forFeature(fromClient.clientFeatureKey, fromClient.reducer)
  ]
})
export class ClientModule { }
