import { Injectable, TemplateRef  } from '@angular/core';
import { LoggingService } from '../logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];
  
  constructor(private loggingService : LoggingService) {}

  show(toastType: string, textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.loggingService.logEvent('System Opened Alert', {
      class: toastType,
      type: 'Toast'
    });    
    this.toasts.push({ toastType, textOrTpl, ...options });
  }
  
  remove(toast) {
    this.loggingService.logEvent('User Closed Alert', {
      class: toast.toastType,
      type: 'Toast'
    });
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}