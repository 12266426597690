import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { PagesSelectors } from 'src/app/pages'
import { SharedModels } from 'src/app/shared'
import {
  IconDefinition,
  faHeadset,
  faFilePdf,
  faFileDownload
} from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'psrs-retiree-payroll-history-pdf-view',
  templateUrl: './retiree-payroll-history-pdf-view.component.html',
  styleUrls: ['./retiree-payroll-history-pdf-view.component.scss']
})
export class RetireePayrollHistoryPdfViewComponent implements OnInit {
  form: SharedModels.FileSnapshot
  subs: Subscription = new Subscription()
  isPdfModalOpen: Boolean = false
  label: string = ''
  route: any[] = []
  cnt: any

  faFileDownload: IconDefinition = faFileDownload
  faHeadset: IconDefinition = faHeadset
  faFilePdf: IconDefinition = faFilePdf

  constructor (
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.cnt = this.router.getCurrentNavigation().extras.state
  }

  ngOnDestroy () {
    this.subs.unsubscribe()
  }

  ngOnInit (): void {
    var pageId = this.activatedRoute.snapshot.params['id']
    this.subs.add(
      this.store
        .select(PagesSelectors.getPage, { id: pageId })
        .subscribe(pg => {
          this.label = pg.header
          this.route = ['/pages', pg.id]
        })
    )
  }

  downloadPdf (downloadUrl: any): void {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank')
    }
  }
}
