import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as MemberActions from './member.actions';
import { MemberService } from '../services/member-service';
import { FileService } from 'src/app/shared/services/file-service';

@Injectable()
export class MemberEffects {

  loadMember$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.loadMember),
      mergeMap(action => 
        this.memberService.getMember(action.id).pipe(
          map(member => MemberActions.loadMemberSuccess({ member })),
          catchError(error => of(MemberActions.loadMemberFailure({ id: action.id, error })))
        )
      )      
    );
  });

  loadMetadata$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.loadMetadata),
      mergeMap(action => 
        this.memberService.getMetadata(action.itemKey).pipe(
          map(metadata => MemberActions.loadMetadataSuccess({ itemKey: action.itemKey, metadata })),
          catchError(error => of(MemberActions.loadMetadataFailure({ itemKey: action.itemKey, error })))
        )
      )      
    );
  });  

  signAttachment$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.signAttachment),
      mergeMap(action => 
        this.fileService.signFile(action.fileKey).pipe(
          map(fileSig => MemberActions.signAttachmentSuccess({ itemKey: action.itemKey, signature: fileSig })),
          catchError(error => of(MemberActions.signAttachmentFailure({ itemKey: action.itemKey, fileKey: action.fileKey, error })))          
        )
      )      
    );
  });    

  loadDrop$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.loadDrop),
      mergeMap(action => 
        this.memberService.getDrop(action.id).pipe(
          map(drop => MemberActions.loadDropSuccess({ drop })),
          catchError(error => of(MemberActions.loadDropFailure({ id: action.id, error })))
        )
      )      
    );
  });

  loadBenefit$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.loadBenefit),
      mergeMap(action => 
        this.memberService.getBenefit(action.id).pipe(
          map(benefit => MemberActions.loadBenefitSuccess({ benefit })),
          catchError(error => of(MemberActions.loadBenefitFailure({ id: action.id, error })))
        )
      )      
    );
  });

  loadFiscal$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.loadFiscal),
      mergeMap(action => 
        this.memberService.getFiscal(action.id).pipe(
          map(fiscal => MemberActions.loadFiscalSuccess({ fiscal })),
          catchError(error => of(MemberActions.loadFiscalFailure({ id: action.id, error })))
        )
      )      
    );
  });  

  loadBeneficiary$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.loadBeneficiary),
      mergeMap(action => 
        this.memberService.getBeneficiary(action.id).pipe(
          map(beneficiary => MemberActions.loadBeneficiarySuccess({ beneficiary })),
          catchError(error => of(MemberActions.loadBeneficiaryFailure({ id: action.id, error })))
        )
      )      
    );
  });
  
  loadAddress$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.loadAddress),
      mergeMap(action => 
        this.memberService.getAddress(action.id).pipe(
          map(address => MemberActions.loadAddressSuccess({ address })),
          catchError(error => of(MemberActions.loadAddressFailure({ id: action.id, error })))
        )
      )      
    );
  });  

  signFiles$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.signFile),
      mergeMap(action => 
        this.fileService.signFile(action.fileKey).pipe(
          map(fileSig => MemberActions.signFileSuccess({ signature: fileSig, docType: action.docType })),
          catchError(error => of(MemberActions.signFileFailure({ fileKey: action.fileKey, error })))          
        )
      )      
    );
  });  

  getOptInMessage$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.loadOptInMessage),
      mergeMap(action => 
        this.memberService.getOptInMessage(action.clientId, action.memberId).pipe(
          map(lstMemberNotificationOptIns => MemberActions.loadOptInMessageSuccess({ lstMemberNotificationOptIns: lstMemberNotificationOptIns })),
          catchError(error => of(MemberActions.loadOptInMessageFailure({ clientId: action.clientId, memberId: action.memberId, error })))          
        )
      )      
    );
  });  

  setOptInMessage$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.setOptInMessage),
      mergeMap(action => 
        this.memberService.setOptInMessage(action.memberId, action.body).pipe(
          map(data => MemberActions.setOptInMessageSuccess()),
          catchError(error => of(MemberActions.setOptInMessageFailure()))          
        )
      )      
    );
  });  

  generatePaymentInfo$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MemberActions.generatePaymentInfo),
      mergeMap(action => 
        this.memberService.generatePaymentInfo(action.paymentHistoryRequest).pipe(
          map(data => MemberActions.generatePaymentInfoSuccess({ paymentHistoryResponse: data })),
          catchError(error => of(MemberActions.generatePaymentInfoFailure()))          
        )
      )      
    );
  });

  constructor(private actions$: Actions, private memberService: MemberService, private fileService: FileService) { }
}