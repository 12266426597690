import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import * as fromCalculator from '../calculator/state/calculator.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CalculatorEffects } from '../calculator/state/calculator.effects';
import { ClientModule } from '../client/client.module';
import { MemberModule } from '../member/member.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromCalculator.calculatorFeatureKey, fromCalculator.coreReducer),
    EffectsModule.forFeature([CalculatorEffects]),
    ClientModule,
    MemberModule,
    NgbModule
  ]
})
export class CalculatorModule { }
