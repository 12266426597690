import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoggingService {
  private appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        accountId: window.location.getClientDomain(),
        enableAutoRouteTracking: false, // option to log all route changes
        autoTrackPageVisitTime: true
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string, properties?: { [key: string]: any }) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
      properties: {
        ClientDomain: window.location.getClientDomain(),
        ...properties
      }
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name}, {
      ClientDomain: window.location.getClientDomain(),
      ...properties      
    });
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, {
      ClientDomain: window.location.getClientDomain(),
      ...properties      
    });
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, {
      ClientDomain: window.location.getClientDomain(),
      ...properties
    });
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }
}