<ng-container [ngSwitch]="page.template">    
    <psrs-generic-template
        *ngSwitchCase="TEMPLATE_NAME.GENERIC"
        [page]="page"
    ></psrs-generic-template>
    <psrs-member-detail-template
        *ngSwitchCase="TEMPLATE_NAME.MEMBER_DETAIL"
        [page]="page"
    ></psrs-member-detail-template>
    <psrs-document-list-template
        *ngSwitchCase="TEMPLATE_NAME.DOCUMENT_LIST"
        [page]="page"
    ></psrs-document-list-template>
    <psrs-drilldown-template
        *ngSwitchCase="TEMPLATE_NAME.DRILLDOWN"
        [page]="page"
    ></psrs-drilldown-template>
    <psrs-accordion-template
        *ngSwitchCase="TEMPLATE_NAME.ACCORDION"
        [page]="page"
    ></psrs-accordion-template>    
    <psrs-retirement-calculator-template
        *ngSwitchCase="TEMPLATE_NAME.RETIREMENT_CALC"
        [page]="page"
    ></psrs-retirement-calculator-template>
    <psrs-retiree-payroll-history
    *ngSwitchCase="TEMPLATE_NAME.RETIREE_PAYROLL_HISTORY"
    [page]="page"
    ></psrs-retiree-payroll-history>
</ng-container>