import { Injectable } from '@angular/core'
import { SeverityLevel } from '@microsoft/applicationinsights-common'
import { LoggingService } from 'src/app/shared'

@Injectable({
  providedIn: 'root'
})
export class CalcUtilService {
  constructor (private loggingService: LoggingService) {}

  calculateTimeElapsed (beginning: Date, end: Date): any {
    this.loggingService.logTrace(
      `Calculating Elasped Time for: [${beginning}] --- [${end}]`
    )
   
    var result = null

    try {
      var miliseconds = end.valueOf() - beginning.valueOf()
      var seconds = miliseconds / 1000
      var minutes = seconds / 60
      var hours = minutes / 60
      var days = hours / 24
      var years = days / 365

      result = this.convertYearsToTimeElapsed(years)
    } catch (err) {
      this.loggingService.logException(err, SeverityLevel.Critical)
    }

    return result
  }

  convertYearsToTimeElapsed (years: Number): any {
    var parts = years.toString().split('.')
    var yrs = parseInt(parts[0])
    var mthDec = parts.length > 1 ? parseFloat(`0.${parts[1]}`) : 0
    var mthAll = 12 * mthDec
    var mths = Math.floor(mthAll)
    var mthParts = mthAll.toString().split('.')
    var dyDec = mthParts.length > 1 ? parseFloat(`0.${mthParts[1]}`) : 0
    var dys = Math.floor(30 * dyDec)

    if (isNaN(yrs)) {
      throw new Error(`Could not Calculate Elasped Time, 'years' isNaN.`)
    } else {
      this.loggingService.logTrace(
        `Calculated Elasped Time at ${yrs} years, ${mths} months, and ${dys} days.`
      )
      return {
        years: yrs,
        months: mths,
        days: dys
      }
    }
  }

  days360 (start: Date, end: Date): any {
    let startMonthDays = 0
    let endMonthDays = 0
    let startDateDayInMonth = this.calculateDaysInMonth(
      start.getFullYear(),
      start.getMonth() + 1
    )
    let endDateDayInMonth = this.calculateDaysInMonth(
      end.getFullYear(),
      end.getMonth() + 1
    )

    if (startDateDayInMonth == start.getDate()) {
      startMonthDays = 0
    } else {
      startMonthDays = 30 - start.getDate()
    }

    if (endDateDayInMonth == end.getDate()) {
      if (start.getDate() < startDateDayInMonth - 1) {
        if (endDateDayInMonth > 30) {
          endMonthDays = endDateDayInMonth
        } else {
          endMonthDays = end.getDate()
        }
      } else {
        if (endDateDayInMonth > 30) {
          endMonthDays = endDateDayInMonth - 1
        } else {
          endMonthDays = end.getDate()
        }
      }
    } else {
      endMonthDays = end.getDate()
    }

    let diff =
      (end.getFullYear() - start.getFullYear()) * 360 +
      (end.getMonth() - start.getMonth() - 1) * 30 +
      startMonthDays +
      endMonthDays

    return diff
  }

  calculateYearsOfService (start: any, end: any, leaveTime: any): any {
    let totalDays = this.days360(start, end)
    if (leaveTime != null) {
      totalDays -=
        leaveTime.years * 360 + leaveTime.months * 30 + leaveTime.days
    }

    var years = Math.floor(totalDays / 360)
    var months = Math.floor((totalDays - years * 360) / 30)
    var days = totalDays - years * 360 - months * 30

    return {
      years: years,
      months: months,
      days: days
    }
  }

  calculateMemberAge (birthday: any, end: any): any {
    let totalDays = this.days360(birthday, end)
    var years = Math.floor(totalDays / 360)
    var months = Math.floor((totalDays - years * 360) / 30)
    var days = totalDays - years * 360 - months * 30

    return {
      years: years,
      months: months,
      days: days
    }
  }

  calculateDaysInMonth (year: any, month: any): any {
    return new Date(year, month, 0).getDate()
  }

  dateDiff (startDate: any, endDate: any): any {
    const result: any = {
      years: 0,
      months: 0,
      days: 0
    }

    // Calculate Years
    if (endDate.getMonth() > startDate.getMonth()) {
      result.years = endDate.getFullYear() - startDate.getFullYear()
    } else if (endDate.getMonth() === startDate.getMonth()) {
      if (endDate.getDate() >= startDate.getDate()) {
        result.years = endDate.getFullYear() - startDate.getFullYear()
      } else {
        result.years = endDate.getFullYear() - startDate.getFullYear() - 1
      }
    } else {
      result.years = endDate.getFullYear() - startDate.getFullYear() - 1
    }

    // Calculate Months
    let offsetMonth = 0
    if (startDate.getDate() > endDate.getDate()) offsetMonth = -1
    if (endDate.getFullYear() - startDate.getFullYear() !== result.years)
      offsetMonth = offsetMonth + 12
    result.months = endDate.getMonth() - startDate.getMonth() + offsetMonth

    // Calculate Days
    const lastDay = new Date(
      endDate.getFullYear(),
      endDate.getMonth() - 1,
      startDate.getDate()
    )
    let tempYear = endDate.getFullYear()
    let tempMonth: number
    if (startDate.getDate() > endDate.getDate()) {
      tempMonth = endDate.getMonth() - 1 > -1 ? endDate.getMonth() - 1 : 11
      tempYear =
        endDate.getMonth() - 1 > -1
          ? endDate.getFullYear()
          : endDate.getFullYear() - 1
    } else tempMonth = endDate.getMonth()

    lastDay.setFullYear(tempYear, tempMonth, startDate.getDate())

    result.days = Math.round(
      (endDate.getTime() - lastDay.getTime()) / (1000 * 60 * 60 * 24)
    )
    return result
  }
}
