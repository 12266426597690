import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthCache } from 'msal/lib-commonjs/cache/AuthCache';

@Injectable({ providedIn: 'root' })
export class AuthService extends MsalService {
  public isAuthenticated:boolean = false; 
  public accessToken:string = '';
  cacheStorage:AuthCache;

  public checkAccount() {
    this.isAuthenticated = !!super.getAccount();
  }

  public logout():void {        
    super.logout();
  }  
}