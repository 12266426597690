<div id="splashPage">
    <img [src]="logoUrl" (load)="imageLoaded($event)" />
    <p><strong>{{ loadingText }}</strong></p>
    <p [innerHtml]="statusText"></p>
    <ng-container *ngIf="fundLoaded && shouldDisplayInstallScreen">
        <div class="install-instr" *ngIf="platform.IOS"><h2>Install APP to Your Home Screen</h2> To install the app on your device,<br />tap share icon <img src="../assets/images/iphone-share-icon.png" class="shareIcon"/> and then <strong>Add to Home Screen</strong>.<span *ngIf="supportMsg"><br /><br >For any issues, please <strong [innerHtml]="supportMsg"></strong>.</span></div>
        <div class="install-instr" *ngIf="(platform.ANDROID || platform.FIREFOX)"><h2>Install APP to Your Home Screen</h2> To install the app on your device,<br />tap share icon <img src="../assets/images/android-menu-icon.png" class="shareIcon"/> and then <strong>Add to Home Screen</strong>.<span *ngIf="supportMsg"><br /><br >For any issues, please <strong [innerHtml]="supportMsg"></strong>.</span></div>
        <div class="install-instr" *ngIf="platform.TRIDENT"><h2>Unsupported Device</h2> This App is not available for installation on this device.<span *ngIf="supportMsg"><br /><br >For any issues, please <strong [innerHtml]="supportMsg"></strong>.</span></div>
    </ng-container>
    <ng-container *ngIf="fundLoaded && !shouldDisplayInstallScreen">
        <div class="install-instr bookmark">
            <div class="proccess-link">Click <a (click)="proceedToApp()" href=""><strong>here</strong></a> to log into your member portal.</div>
            <div>We recommend you bookmark this page or save it to your home screen. Instructions for both are below.</div>            
            <h2>Add to Home Screen</h2>
            <div><strong>If you are using Safari or Chrome on Apple iOS (iPhone or iPad)</strong></div>
            <ol>
                <li>Tap the share button (it looks like a box with an up arrow coming out of it).</li>
                <li>Tap on "Add to Home Screen".</li>
            </ol>
            <div><strong>If you are using Chrome on Android</strong></div>
            <ol>
                <li>Tap the three dots in the top right of your web browser.</li>
                <li>Tap on "Add to Home Screen".</li>
            </ol>         
            <h2>Bookmark</h2>
            <div><strong>If you are using Safari</strong></div>
            <ol>
                <li>Tap the share button (it looks like a box with an up arrow coming out of it).</li>
                <li>Tap on "Add Bookmark".</li>
            </ol>
            <div><strong>If you are using Chrome</strong></div>
            <ol>
                <li>Tap the three dots in the top right of your web browser.</li>
                <li>Tap on "Add to Bookmarks" (or star icon).</li>
            </ol>
        </div>
    </ng-container>    
</div>