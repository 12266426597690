import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core'

import { PDFDocumentProxy } from 'ng2-pdf-viewer'
import { FileSnapshot } from 'src/app/shared/models'
import { SignatoryService } from 'src/app/shared/services/signatory-service'
import {
  faFileDownload,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'psrs-display-payroll-pdf',
  templateUrl: './display-payroll-pdf.component.html',
  styleUrls: ['./display-payroll-pdf.component.scss']
})
export class DisplayPayrollPdfComponent implements OnInit {
  faFileDownload: IconDefinition = faFileDownload
  collectionSize: number = 0
  page: number = 1
  fileUri: string

  @Input() PdfFile: FileSnapshot

  @Input() pdfUrl: string

  @Output() closeShowPdf = new EventEmitter<boolean>()

  constructor (
    private signatoryService: SignatoryService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  pdfWidth: number
  zoomScale: number = 1.0

  @HostListener('window:resize', ['$event'])
  onResize (event: Event): void {
    this.updateWidth()
    this.updateZoom()
  }

  private updateWidth (): void {
    this.pdfWidth = window.innerWidth - (window.innerWidth * 10) / 100
  }

  private updateZoom (): void {
    if (window.innerWidth <= 768) {
      this.zoomScale = this.pdfWidth / 800
    } else {
      this.zoomScale = this.pdfWidth / 1000
    }
  }

  ngOnInit (): void {
    this.updateWidth()
    this.updateZoom()

    this.fileUri = this.signatoryService.getUrl(this.PdfFile)
  }

  pageChanged (pageNo: number): void {
    this.page = pageNo
  }

  afterLoadComplete (pdfDoc: PDFDocumentProxy): void {
    this.collectionSize = pdfDoc.numPages * 10
  }

  sendDataToParent (): void {
    // Sending data back to the parent
    this.closeShowPdf.emit(false)
  }
}
