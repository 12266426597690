import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  Renderer2,
  ElementRef
} from '@angular/core'

@Component({
  selector: 'psrs-desktop-pdf-modal',
  templateUrl: './desktop-pdf-modal.component.html',
  styleUrls: ['./desktop-pdf-modal.component.scss']
})
export class DesktopPdfModalComponent implements OnInit {
  @Input() pdfUrl: string
  @Input() modalHeading: any

  @Output() closeModal: EventEmitter<any> = new EventEmitter()
  @Output() pdfDownload: EventEmitter<any> = new EventEmitter()

  pdfWidth: number
  zoomScale: number = 1.0

  close (): void {
    this.closeModal.emit()
  }
  download (): void {
    this.pdfDownload.emit()
  }

  constructor (private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit (): void {
    this.updateWidth()
    this.updateZoom()
  }

  @HostListener('window:resize', ['$event'])
  onResize (event: Event): void {
    this.updateWidth()
    this.updateZoom()
  }

  private updateWidth (): void {
    this.pdfWidth = window.innerWidth - (window.innerWidth * 20) / 100
  }

  private updateZoom (): void {
    if (window.innerWidth <= 768) {
      this.zoomScale = this.pdfWidth / 800
    } else {
      this.zoomScale = this.pdfWidth / 1000
    }
  }
}
