import { createReducer, on } from '@ngrx/store';
import * as MetadataActions from './actions';
import { MetadataState } from './state';

export const metadataFeatureKey = 'metadata';
export const initialState:MetadataState = { };

export const reducer = createReducer<MetadataState>(initialState,
  on(MetadataActions.loadMetadataSuccess, (state, { metadata }) => { 
    return {
        ...state,
        data: metadata
    };
  }),
  on(MetadataActions.loadMetadataFailure, (state, { error }) => state),  
);