import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCalculator from './calculator.reducer';
import { CalculatorState } from './calculator.state';

const selectCalculatorState = createFeatureSelector<CalculatorState>(fromCalculator.calculatorFeatureKey);
const selectCalculatorErrorState = createSelector(selectCalculatorState, state => state.error);
const selectCalculatorViewState = createSelector(selectCalculatorState, state => state.views);
const selectCalculatorDataState = createSelector(selectCalculatorState, state => state.data);

export const getCalculatorError = createSelector(selectCalculatorErrorState, state => state);

export const getPensionEstimatorVM = createSelector(selectCalculatorViewState, state => state.pensionEstimator);

export const getEstimatedPension = createSelector(selectCalculatorDataState, state => state.estimatedPension);