import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PageDefinition } from '../models';
import * as fromPages from './pages.reducer';
import { PagesState } from './pages.state';

const selectPagesState = createFeatureSelector<PagesState>(fromPages.pagesFeatureKey);
const selectPagesErrorState = createSelector(selectPagesState, state => state.error);
const selectPagesDataState = createSelector(selectPagesState, state => state.data);

export const getPagesError = createSelector(selectPagesErrorState, state => state);

export const getPages = createSelector(selectPagesDataState, (state:PageDefinition[]) => state);
export const getPage = createSelector(selectPagesDataState, (state:PageDefinition[], props) => state.find(x => x.id === props.id));
export const getFirstPage = createSelector(selectPagesDataState, (state:PageDefinition[], props) => state[0]);