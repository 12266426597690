import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app.routes';
import { AppComponent } from './components/bootstrap/app.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './components/menu/menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ObserversModule } from '@angular/cdk/observers';
import { SplashComponent } from './components/splash/splash.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { ClientModule } from '../client/client.module';
import { MemberModule } from '../member/member.module';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppEffects, fromApp } from './state';
import { CalculatorModule } from '../calculator/calculator.module';
import { FundIdentifierComponent } from './components/fund-identifier/fund-identifier.component';
import { UnspecifiedFundComponent } from './components/unspecified-fund/unspecified-fund.component';
import { PagesModule } from '../pages/pages.module';
import { MetadataModule } from '../metadata/metadata.module';
import { PromptFundComponent } from './components/prompt-fund/prompt-fund.component';
import { HeaderComponent } from './components/header/header.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DesktopPdfModalComponent } from '../pages/components/templates/document-list/desktop-pdf-modal/desktop-pdf-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    SplashComponent,
    FundIdentifierComponent,
    UnspecifiedFundComponent,
    PromptFundComponent,
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatMenuModule,

    MatButtonModule,
    MatIconModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    ObserversModule,
    EffectsModule.forRoot(),
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    StoreModule.forFeature(fromApp.appFeatureKey, fromApp.reducer),
    EffectsModule.forFeature([AppEffects]),
    ClientModule,
    MemberModule,
    SharedModule,
    NgbModule,
    CalculatorModule,
    PagesModule,
    MetadataModule,
    MatDialogModule,
    MatSlideToggleModule
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule {}
