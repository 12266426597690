import { Injectable, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { BehaviorSubject, Subject, timer } from 'rxjs'
import { auth0Config } from '../auth/auth0.config'

@Injectable({ providedIn: 'root' })
export class Auth0Service implements OnDestroy {
  isIframe = false
  loginDisplay = false
  private readonly _destroying$ = new Subject<void>()
  accountActivate: any = new BehaviorSubject(null)
  idTokenClaim: any
  role: string = ''
  fundKey: string = ''
  expireTime$: any
  email: string = ''
  name: string = ''
  sub: string = ''
  adminName: string = ''
  //Auth0 props
  readonly appState$ = this.auth0.appState$
  readonly isAuthenticated$ = this.auth0.isAuthenticated$
  readonly isLoading$ = this.auth0.isLoading$
  readonly error$ = this.auth0.error$
  readonly idTokenClaim$ = this.auth0.idTokenClaims$
  readonly user$ = this.auth0.user$
  readonly auth0Config = auth0Config

  constructor (private auth0: AuthService, private router: Router) {}
  initializeAuth (): void {
    this.isIframe = window !== window.parent && !window.opener // Remove this line to use Angular Universal
    this.idTokenClaim$.subscribe(el => {
      this.idTokenClaim = el
      localStorage.setItem('idTokenClaim', JSON.stringify(this.idTokenClaim))

      if (!localStorage.getItem('name')) {
        this.name = this.idTokenClaim.name
        localStorage.setItem('name', this.name)
      } else this.name = localStorage.getItem('name') as string

      if (!localStorage.getItem('email')) {
        this.email = this.idTokenClaim.email
        localStorage.setItem('email', this.email)
      } else this.email = localStorage.getItem('email') as string

      if (!localStorage.getItem('sub')) {
        this.sub = this.idTokenClaim.sub
        localStorage.setItem('sub', this.sub)
      } else this.sub = localStorage.getItem('sub') as string

      if (!localStorage.getItem('adminName')) {
        this.adminName = `${el.given_name ? el.given_name : ''} ${
          el.family_name ? el.family_name : ''
        }`
        localStorage.setItem('adminName', this.adminName)
      } else this.adminName = localStorage.getItem('adminName') as string
    })
  }
  login () {
    this.auth0.loginWithRedirect()
  }
  logout () {
    var domain = window.location.getClientDomain()
    localStorage.clear()
    sessionStorage.clear()
    this.auth0.logout()

    setTimeout(() => {
      window.location.href = `${window.location.origin}/f/${domain}#${domain}`
    }, 100)
  }
  isLoggedIn () {
    return this.isAuthenticated$.pipe().subscribe(el => el)
  }
  ngOnDestroy (): void {
    this._destroying$.next(undefined)
    this._destroying$.complete()
  }
}
