export class Address {
    street1: string;
    street2: string;
    city: string;
    state: string;
    zipCode: string;
    zipPlus4: string;
    country: string;

    constructor(obj: any) {
        this.street1 = obj?.street1;
        this.street2 = obj?.street2;
        this.city = obj?.city;
        this.state = obj?.state;
        this.zipCode = obj?.zipCode;
        this.zipPlus4 = obj?.zipPlus4;
        this.country = obj?.country;
    }

    public get full() : string {
        let result = this.street1;
        
        if (this.street2?.length) {
            result += `<br />${this.street2}`;
        }
    
        if (this.city?.length || this.state?.length || this.zipCode?.length || this.zipPlus4?.length) {
            result += `<br />`;
        }

        result += `${this.city}, ${this.state} ${this.zipCode}`;

        if (this.zipPlus4?.length) {
            result += `-${this.zipPlus4}`;
        }

        if (this.country?.length) {
            result += `<br />${this.country}`;
        }
        
        return result;
    }    
}