import { Component, OnInit, Input } from '@angular/core';
import { LoadingIndicatorService } from 'src/app/shared/services/util/loadingIndicator.service';

@Component({
  selector: 'progressMask',
  templateUrl: './progressMask.component.html',
  styleUrls: ['./progressMask.component.scss']
})
export class ProgressMaskComponent implements OnInit {
  showProgress:boolean = false;
  constructor(private loadingIndicatorService: LoadingIndicatorService) { }

  ngOnInit() {
    this.loadingIndicatorService
    .onLoadingChanged
    .subscribe(isLoading => this.showProgress = isLoading);
  }

}