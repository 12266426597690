import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MemberEffects, fromMember } from './state';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,    
    StoreModule.forFeature(fromMember.memberFeatureKey, fromMember.memberReducers),
    EffectsModule.forFeature([MemberEffects])
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    DatePipe
  ]
})
export class MemberModule { }
