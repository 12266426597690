import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggingService } from 'src/app/shared';
import { CalculatorFieldBaseComponent } from '../calculator-field-base';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'psrs-calc-currency-field',
  templateUrl: './calc-currency-field.component.html',
  styleUrls: ['./calc-currency-field.component.scss']
})
export class CalcCurrencyFieldComponent extends CalculatorFieldBaseComponent implements OnInit {

  @ViewChild("salary") salaryInput: TemplateRef<HTMLInputElement>;
  @Input() isDisabled: Boolean = false;
  hasValidationErrors: Boolean = false;
  salaryDisplay: string = '';

  constructor(private _loggingService:LoggingService, private _modalService:NgbModal, private sanitizer:DomSanitizer) { 
    super(_loggingService, _modalService)
  }

  ngOnInit(): void {
    if(!this.defaultValue) {
      return;
    }
    let val = Number(this.defaultValue.toString().replace(/[^\d\.]/gi,''));
    let result = this.formatCurrency(val);
    this.salaryDisplay = result.formatted;

    setTimeout(() => {
      this.formFieldChanged(null);
    }, 500);    
  }  

  formFieldChanged(evt): void {
    let salary = this.salaryInput['nativeElement'];
    var newVal = salary.value.replace(/[^\d]/gi,'');
    
    this.hasValidationErrors = false;
    if(newVal != '' && !isNaN(newVal) && newVal > 0) {
      var num = Number(newVal) * 0.01;
      num =  num > 9999999.99 ? 9999999.99 : num;
      let result = this.formatCurrency(num);
      salary.value = result.formatted;
      this.publishChangedValue(result.number);     
    } else if(newVal.length == 0 || (!isNaN(newVal) && newVal <= 0)) {
      this.hasValidationErrors = true;
    }
    this.publishChangedStatus(!this.hasValidationErrors);
  }

  formatCurrency(amount:number): any {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });        
    var currency = formatter.format(amount);
    return {
      formatted: currency.replace('$', ''),
      number: amount
    };
  }

  safeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}