import { CurrencyPipe, DatePipe } from '@angular/common'
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Router } from '@angular/router'
import {
  IconDefinition,
  faFileDownload,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { Subscription, combineLatest } from 'rxjs'
import { MemberSelectors } from 'src/app/member'
import { Member } from 'src/app/member/models'
import { MetadataModels, MetadataSelectors } from 'src/app/metadata'
import { PagesModels } from 'src/app/pages'
import { PagesService } from 'src/app/pages/services/pages.service'
import { LoggingService } from 'src/app/shared'
import { FileSnapshot } from 'src/app/shared/models'
import { SignatoryService } from 'src/app/shared/services/signatory-service'
import * as fileSaver from 'file-saver'

@Component({
  selector: 'psrs-member-detail-template',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss']
})
export class MemberDetailComponent implements OnInit, OnDestroy {
  private _page: PagesModels.PageDefinition
  readonly ATTACHMENT_KEY = '#attachment'
  propertyListAttachmentConfig: { sectionKey: string; label: string } = {
    sectionKey: '',
    label: ''
  }
  propertyTableAttachmentConfig: { sectionKey: string; label: string } = {
    sectionKey: '',
    label: ''
  }

  @Input() set page (value: PagesModels.PageDefinition) {
    this._page = value
    this.redrawComponent()
  }

  get page (): PagesModels.PageDefinition {
    return this._page
  }

  TEMPLATE_KEY: any = {
    SupportContact: 'supportContact',
    Subheader: 'subheader',
    PropertyList: 'propertyList',
    PropertyListLayout: 'propertyListLayout',
    PropertyTable: 'propertyTable',
    Footnote: 'footnote',
    Attachment: 'attachment',
    AttachmentText: 'attachmentText'
  }
  PROPERTY_TYPE: any = {
    SSN: 'SSN',
    Phone: 'Phone',
    Email: 'Email',
    RichText: 'RichText',
    Currency: 'Currency',
    Date: 'Date',
    DateTime: 'DateTime',
    PersonName: 'Person_Name',
    Address: 'Address',
    Lookup: 'Lookup',
    Percentage: 'Percentage',
    Binary: 'Binary',
    Boolean: 'Boolean',
    Department: 'Department',
    Employer: 'Employer',
    Decimal: 'Decimal'
  }

  AGGREGATION_TYPE: any = {
    Count: 'Count',
    Min: 'Min',
    Max: 'Max',
    Sum: 'Sum',
    Average: 'Average'
  }

  subs: Subscription = new Subscription()
  faFilePdf: IconDefinition = faFilePdf
  faFileDownload: IconDefinition = faFileDownload
  fileAttachment: FileSnapshot
  buttonText: string
  memberMetadata: Member
  metadata: MetadataModels.Metadata[]
  propertyList: any[] = []
  propertyTable: any
  listDisplay: string = 'Table'
  richTextContent: string
  richTextTitle: string
  @ViewChild('rickTextModal')
  rickTextModal: TemplateRef<any>
  rickTextModalId: string
  allSelected: boolean = false
  sectionName: string = ''
  isSelected: boolean = false
  isLoading: boolean = false

  constructor (
    private sanitized: DomSanitizer,
    private store: Store,
    private loggingService: LoggingService,
    private router: Router,
    private signatoryService: SignatoryService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private pagesService: PagesService
  ) {}

  ngOnDestroy (): void {
    this.subs.unsubscribe()
  }

  ngOnInit (): void {
    this.subs.add(
      combineLatest([
        this.store.select(MetadataSelectors.getAll),
        this.store.select(MemberSelectors.getMetadataAll)
      ]).subscribe(([md, mem]) => {
        this.metadata = md
        this.memberMetadata = mem
        this.redrawComponent()
      })
    )
  }

  redrawComponent (): void {
    this.propertyList = []
    let attachmentContent = this.page.content.find(
      c => c.key === this.TEMPLATE_KEY.Attachment
    )
    if (attachmentContent?.value != null) {
      var metadataContent = this.memberMetadata[attachmentContent.value]
      if (
        metadataContent &&
        metadataContent.attachments != null &&
        metadataContent.attachments.length > 0
      ) {
        var attachments = [...metadataContent.attachments]
        attachments.sort(function (a, b) {
          return (
            new Date(b.dateCreated).getTime() -
            new Date(a.dateCreated).getTime()
          )
        })

        this.fileAttachment = attachments[0].fileSnapshot
        var attachmentText = this.page.content.find(
          c => c.key === this.TEMPLATE_KEY.AttachmentText
        )
        this.buttonText = attachmentText?.value ?? 'Download'
      }
    }

    if (this.memberMetadata && this.metadata) {
      let propertyListContent = this.page.content.find(
        c => c.key === this.TEMPLATE_KEY.PropertyList
      )
      if (propertyListContent?.value != null) {
        const attachmentConfig =
          (Object.values(propertyListContent.value) as string[]).find(value =>
            value.split('.')?.[0]?.includes(this.ATTACHMENT_KEY)
          ) || ''
        this.propertyListAttachmentConfig =
          this.getAttachmentConfig(attachmentConfig)
        let propList = propertyListContent.value
        for (let idx in propList) {
          let parts = propList[idx].split('.')
          if (parts[0]?.includes(this.ATTACHMENT_KEY)) {
            continue
          }
          let mdi = this.metadata.find(x => x.key === parts[0])
          let mdp = mdi.properties.find(x => x.key === parts[1])
          let mdm = this.memberMetadata[parts[0]]

          if (mdm) {
            if (
              mdp &&
              mdm.table &&
              mdi.aggregations != null &&
              mdi.aggregations.length > 0
            ) {
              let aggregationType = mdi.aggregations.find(
                x => x.id === parts[2]
              ).aggregation
              if (aggregationType != null) {
                let value = this.getAggregationValue(
                  aggregationType,
                  mdm.table,
                  parts[1],
                  mdp.type
                )
                this.propertyList.push({
                  property: mdp,
                  value: value,
                  isAggregated: true
                })
              }
            } else {
              let mdv = mdm[parts[1]]
              if (mdv) {
                this.propertyList.push({
                  property: mdp,
                  value: mdv,
                  isAggregated: false
                })
              }
            }
          }
        }
      }
      let propertyTableContent = this.page.content.find(
        c => c.key === this.TEMPLATE_KEY.PropertyTable
      )
      if (propertyTableContent?.value != null) {
        const contentValues = Object.values(
          propertyTableContent.value
        ) as string[]
        const attachmentConfig =
          contentValues.find(value =>
            value.split('.')?.[0]?.includes(this.ATTACHMENT_KEY)
          ) || ''
        this.propertyTableAttachmentConfig =
          this.getAttachmentConfig(attachmentConfig)
        const sectionKey =
          contentValues.find(
            value => !value.split('.')?.[0]?.includes(this.ATTACHMENT_KEY)
          ) || ''
        let md = this.memberMetadata[sectionKey]
        if (md) {
          this.propertyTable = {
            properties: this.metadata.find(x => x.key === sectionKey)
              .properties,
            values: md.table.map(data => {
              return { ...data, isSelected: false }
            })
          }
        } else {
          this.propertyTable = undefined
        }
      }

      let propertyListLayout = this.page.content.find(
        c => c.key === this.TEMPLATE_KEY.PropertyListLayout
      )
      if (propertyListLayout?.value) {
        this.listDisplay = propertyListLayout.value
      }
    }
  }

  getDisplayValue (prop: MetadataModels.MetadataProperty, propValue): any {
    if (!prop.type || !propValue) {
      return null
    }

    try {
      switch (prop.type) {
        case this.PROPERTY_TYPE.Currency:
          propValue = this.currencyPipe.transform(propValue)
          break
        case this.PROPERTY_TYPE.Date:
          propValue = this.datePipe.transform(propValue, 'MM/dd/yyyy')
          break
        case this.PROPERTY_TYPE.DateTime:
          propValue = this.datePipe.transform(propValue, 'MM/dd/yyyy h:mm a')
          break
        case this.PROPERTY_TYPE.PersonName:
          var prefix = this.getOptionValue(prop, propValue.prefix, '')
          var persion = {
            prefix: prefix,
            first: propValue.first,
            middle: propValue.middle,
            last: propValue.last,
            suffix: propValue.suffix
          }
          propValue = new MetadataModels.PersonName(persion).full

          break
        case this.PROPERTY_TYPE.Address:
          var country = ''
          var state = ''
          if (propValue.country) {
            country = this.getOptionValue(prop, propValue.country)
          }

          if (propValue.state) {
            state = this.getOptionValue(prop, propValue.state, '', true)
          }

          var address = {
            street1: propValue.street1,
            street2: propValue.street2,
            city: propValue.city,
            state: state,
            zipCode: propValue.zipCode,
            zipPlus4: propValue.zipPlus4,
            country: country
          }
          propValue = new MetadataModels.Address(address).full
          break
        case this.PROPERTY_TYPE.Department:
        case this.PROPERTY_TYPE.Employer:
        case this.PROPERTY_TYPE.Lookup:
          propValue = this.getOptionValue(prop, propValue)
          break
        case this.PROPERTY_TYPE.Percentage:
          propValue = `${propValue}%`
          break
        case this.PROPERTY_TYPE.Binary:
        case this.PROPERTY_TYPE.Boolean:
          if (propValue == 'true') {
            propValue = prop.config.affirmative
          } else {
            propValue = prop.config.negative
          }
          break
        case this.PROPERTY_TYPE.SSN:
        case this.PROPERTY_TYPE.Email:
        case this.PROPERTY_TYPE.Phone:
          if (prop.config.masked == 'true') {
            propValue = propValue.value != null ? propValue.value : propValue
          } else {
            propValue =
              prop.config.negative != null ? prop.config.negative : propValue
          }
          break
        case this.PROPERTY_TYPE.Decimal:
          if (prop.config.fractionalLength == 'true') {
            let fractionalLength =
              prop.config.fractionalLengthInput != null
                ? prop.config.fractionalLengthInput
                : 0
            let factor = 10 ** fractionalLength
            propValue = Math.floor(propValue * factor) / factor
          } else {
            propValue = Math.floor(propValue)
          }
          break
      }
    } catch (error) {}

    return propValue
  }

  getOptionValue (
    prop: MetadataModels.MetadataProperty,
    propValue,
    defaultValue = null,
    isState = false
  ): any {
    let opt = prop.options.find(
      x => x.id === propValue || x.text === propValue || x?.code === propValue
    )

    if (isState) return opt?.description ?? defaultValue ?? ''

    if (prop.name.toLowerCase() === 'tier') {
      return opt?.code ?? defaultValue ?? ''
    }

    return opt?.text ?? defaultValue ?? ''
  }

  getAggregationValue (type: any, values: any, key: any, dataType: any): any {
    let count = 0
    let total = 0
    switch (type) {
      case this.AGGREGATION_TYPE.Count:
        count = 0
        values.forEach(element => {
          if (element[key]) {
            count += 1
          }
        })

        return count

      case this.AGGREGATION_TYPE.Sum:
        total = 0
        values.forEach(element => {
          total += element[key]
        })

        return total

      case this.AGGREGATION_TYPE.Average:
        total = 0
        count = 0
        values.forEach(element => {
          total += element[key]
          count += 1
        })

        return count > 0 ? total / count : count

      case this.AGGREGATION_TYPE.Min:
        let min = ''
        values.forEach(element => {
          min =
            min == '' ? element[key] : min > element[key] ? element[key] : min
        })

        if (min != '' && dataType == this.PROPERTY_TYPE.DateTime) {
          min = this.datePipe.transform(min, 'MM/dd/yyyy h:mm a')
        }

        return min
      case this.AGGREGATION_TYPE.Max:
        let max = ''
        values.forEach(element => {
          max =
            max == '' ? element[key] : max < element[key] ? element[key] : max
        })

        if (max != '' && dataType == this.PROPERTY_TYPE.DateTime) {
          max = this.datePipe.transform(max, 'MM/dd/yyyy h:mm a')
        }

        return max
      default:
        return ''
    }
  }

  showRichTextModel (item: any): any {
    this.richTextContent = item.value
    this.richTextTitle = item.property.name
    this.modalService.open(this.rickTextModal, {
      backdrop: 'static',
      scrollable: true,
      keyboard: false,
      centered: true
    })
  }

  closeDisplay (): void {
    this.modalService.dismissAll()
  }

  onClickIcon (status: any) {}

  safeHtml (html: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(html)
  }

  viewAttachmentList (attachmentSectionKey?: string, rowId?: string) {
    if (!attachmentSectionKey) {
      return
    }

    let attachmentList =
      this.memberMetadata?.[attachmentSectionKey]?.attachments || []
    if (rowId) {
      attachmentList = attachmentList.filter(
        attachment => attachment.rowId === rowId
      )
    }
    if (attachmentList?.length === 1) {
      const downloadUrl = this.signatoryService.getUrl(
        attachmentList[0].fileSnapshot
      )
      if (downloadUrl) {
        this.loggingService.logEvent('User Downloaded Pdf')
        window.open(downloadUrl, '_blank')
        return
      }
    }

    this.router.navigate(['pages', this.page.id, 'attachment-list'], {
      state: {
        page: this.page,
        listLabel: this.propertyListAttachmentConfig.label,
        attachmentList,
        rowId
      },
      fragment: window.location.getClientDomain()
    })
  }

  getAttachmentConfig (attachmentConfig: string) {
    if (!attachmentConfig) {
      return { sectionKey: '', label: '' }
    }
    return {
      sectionKey: attachmentConfig.split('.')[1] || '',
      label: attachmentConfig.split('.')[2] || ''
    }
  }

  hasAttachment (attachmentSectionKey: string): boolean {
    if (!attachmentSectionKey) {
      return false
    }

    const attachmentList =
      this.memberMetadata?.[attachmentSectionKey]?.attachments || []
    return attachmentList.length > 0
  }

  onChange (event: any, data?: any) {
    const valueInput = event.target.checked
    if (data) {
      data.isSelected = valueInput
      this.allSelected = this.propertyTable.values.every(
        value => value.isSelected
      )
    } else {
      this.allSelected = valueInput
      this.propertyTable.values.forEach(value => {
        if (value.noOfAttachments) {
          value.isSelected = this.allSelected
        }
      })
    }
    const findValueSelected = this.propertyTable.values.find(
      value => value.isSelected
    )
    this.isSelected = findValueSelected ? true : false
  }

  downloadMulti (attachmentSectionKey: string) {
    const listFileSelected = this.propertyTable.values.filter(
      value => value.isSelected
    )
    if (!attachmentSectionKey || !listFileSelected?.length) {
      return false
    }
    if (!this.sectionName) {
      this.sectionName = this.metadata.find(
        (value: any) => value.key === attachmentSectionKey
      )?.name
    }
    let listAttachmentSelected = []
    listFileSelected.forEach(value => {
      const checkAttachment = this.memberMetadata[
        attachmentSectionKey
      ]?.attachments.filter(item => item.rowId === value.rowId)
      if (checkAttachment.length) {
        listAttachmentSelected = listAttachmentSelected.concat(checkAttachment)
      }
    })
    const body = listAttachmentSelected.map(item => item.fileSnapshot.key)
    this.isLoading = true
    this.pagesService.exportMultiFile(body).subscribe(
      res => {
        if (res) {
          const blob = this.base64ToBlob(res, 'text/plain')
          const date = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss')
          fileSaver.saveAs(blob, `${this.sectionName}_${date}.zip`)
          // reset data
          this.propertyTable.values.forEach(item => {
            item.isSelected = false
          })
          this.allSelected = false
          this.isSelected = false
        }
        this.isLoading = false
      },
      error => {
        this.isLoading = false
      }
    )
  }

  base64ToBlob (b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, '') //IE compatibility...
    const byteCharacters = atob(b64Data)
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, { type: contentType })
  }
}
