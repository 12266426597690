import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ptg-masked-eye',
  templateUrl: './masked-eye.component.html',
  styleUrls: ['./masked-eye.component.scss']
})
export class MaskedEyeComponent implements OnInit {
  @Input() propertyValue!: any;
  @Input() masked!: any;
  isMasked!: boolean;
  value!: any;
  isVisible:boolean;
  constructor(
  ) { }

  ngOnInit(): void {
    this.isMasked = this.masked == "true";
    this.isVisible = false;
    if(this.isMasked && this.propertyValue.value) {
      this.value = this.propertyValue.value;
      this.isVisible = true;
    }
    else{
      this.value = this.propertyValue.original;
    }
  }

  onClickIcon() {
    this.isMasked = !this.isMasked;
    if(this.isMasked) {
      this.value = this.propertyValue.value;
    }
    else{
      this.value = this.propertyValue.original;
    }
  }
}
