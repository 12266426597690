<div class="deskto_p">
  <div class="member_det mob_pad">
    <h1>{{ listLabel }}</h1>

    <div *ngFor="let content of attachmentList" class="topmargin15 attach_grd">
      <div class="ti_tle">
        <div class="cols documentName">{{ content.documentName }}</div>
        <div class="cols docDate">
          Posted on:
          {{ content.fileSnapshot.lastModified | date : "mediumDate" }}
        </div>
      </div>
      <div class="des_crp">
        <div class="cols description">{{ content.description }}</div>
      </div>
      <div
        *ngIf="!content.signatureRequred || content.signature != null"
        class="row btns_grps"
      >
        <button (click)="getPdfFile(content)" class="pdfButton topmargin10">
          <fa-icon [icon]="faFilePdf" class="faIcon pdfIcon"></fa-icon>
          View
        </button>
        <!-- <button
          (click)="getPdfFile(content, true)"
          class="pdfButton topmargin10"
        >
          <fa-icon [icon]="faFileDownload" class="faIcon pdfIcon"></fa-icon>
          Download
        </button> -->
      </div>
    </div>

    <psrs-desktop-pdf-modal
      *ngIf="isPdfModalOpen === true"
      [modalHeading]="modalLabel"
      [pdfUrl]="selectedPdfUrl"
      (closeModal)="closePdfModal()"
      (pdfDownload)="downloadPdf()"
    ></psrs-desktop-pdf-modal>
  </div>
</div>
