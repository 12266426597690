<!-- <div class="mainContent">
  <div class="row">
    <h1>{{ page.header }}</h1>
  </div>
  <div class="topmargin15" *ngFor="let cnt of contentList">
    <div class="row">
      <div class="col-12 documentName">
        {{ displayFileName(cnt.file.fileName) }}
      </div>
    </div>
    <div class="topmargin15" *ngFor="let cnt of contentList">
      <div class="row">
        <div class="col-12 documentName">
          {{ cnt.label }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 docDate">
          Posted on: {{ cnt.datePosted | date: 'mediumDate' }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 description">{{ cnt.description }}</div>
      </div>
      <div class="row" *ngIf="!cnt.signatureRequred || cnt.signature != null">
        <div class="col-6 paddingleft0">
          <button
            class="pdfButton topmargin10"
            (click)="displayPdf(contentList.indexOf(cnt))"
          >
            <fa-icon [icon]="faFilePdf" class="faIcon pdfIcon"></fa-icon>View
          </button>
        </div>
        <div class="col-6 paddingright0">
          <button class="pdfButton topmargin10" (click)="downloadPdf(cnt.file)">
            <fa-icon [icon]="faFileDownload" class="faIcon pdfIcon"></fa-icon
            >Download
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 docDate">
          Posted on: {{ cnt.datePosted | date: 'mediumDate' }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 description">{{ cnt.description }}</div>
      </div>
      <div class="row" *ngIf="!cnt.signatureRequred || cnt.signature != null">
        <div class="col-6 paddingleft0">
          <button
            class="pdfButton topmargin10"
            (click)="displayPdf(contentList.indexOf(cnt))"
          >
            <fa-icon [icon]="faFilePdf" class="faIcon pdfIcon"></fa-icon>View
          </button>
        </div>
        <div class="col-6 paddingright0">
          <button class="pdfButton topmargin10" (click)="downloadPdf(cnt.file)">
            <fa-icon [icon]="faFileDownload" class="faIcon pdfIcon"></fa-icon
            >Download
          </button>
        </div>
      </div>
    </div>
  </div> -->

<!-- Desktop View -->

<div class="deskto_p">
  <div class="mob_pad">
    <div class="row doc_head">
      <h1>{{ page.header }}</h1>
    </div>
    <div class="topmargin15 document_list" *ngFor="let cnt of contentList">
      <div class="doc_head">
        <div class="row">
          <div class="col-12 documentName">
            {{ cnt.label }}
            <!-- {{ displayFileName(cnt.file.fileName) }} -->
          </div>
        </div>
        <div class="row">
          <div class="col-12 docDate">
            Posted on: {{ cnt.datePosted | date: 'mediumDate' }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 description">{{ cnt.description }}</div>
      </div>
      <div class="row" *ngIf="!cnt.signatureRequred || cnt.signature != null">
        <div class="col-6 paddingleft0">
          <button
            class="pdfButton topmargin10"
            (click)="displayDesktopPdf(contentList.indexOf(cnt))"
          >
            <fa-icon [icon]="faFilePdf" class="faIcon pdfIcon"></fa-icon>View
          </button>
        </div>
      </div>
    </div>
    <psrs-desktop-pdf-modal
      *ngIf="isPdfModalOpen === true"
      [modalHeading]="modalLabel"
      [pdfUrl]="selectedPdfUrl"
      (closeModal)="closePdfModal()"
      (pdfDownload)="downloadPdf(downloadPdfData)"
    ></psrs-desktop-pdf-modal>
  </div>
</div>
<!-- (pdfDownload)="downloadPdf()" -->
