import { createFeatureSelector, createSelector } from '@ngrx/store';
import { metadataFeatureKey } from './reducer';
import { MetadataState } from './state';

const selectMetadataState = createFeatureSelector<MetadataState>(metadataFeatureKey);

export const getAll = createSelector(selectMetadataState, (state:MetadataState) => state.data);
export const getItem = createSelector(selectMetadataState, (state:MetadataState, props) => state.data?.find(md => md.key === props.key));
export const resolveProperty = createSelector(selectMetadataState, (state:MetadataState, itemKey:string) => {
    if(!itemKey) {
        return null;
    }
    let keyParts = itemKey.split('.');
    let mdItemKey = keyParts[0];
    let mdPropKey = keyParts[1];

    let mdItem = state.data?.find(md => md.key === mdItemKey);
    let mdProp = mdItem.properties.find(mp => mp.key === mdPropKey);

    return mdProp;
});