<div class="modal pdf_modal">
  <div class="modal-content">
    <h1>{{ modalHeading }}</h1>

    <div class="pdf-conntainer">
      <!-- <pdf-viewer
        [src]="pdfUrl"
        [render-text]="true"
        [autoresize]="true"
        [zoom]=".8"
        [zoom-scale]="'page-width'"
        style="display: block; width: 100%; height: 100vh;"
      ></pdf-viewer> -->
      <pdf-viewer
        [src]="pdfUrl"
        [render-text]="true"
        [autoresize]="true"
        style="display: block; width: '{{ pdfWidth }}% !important'"
        [zoom]="zoomScale"
      ></pdf-viewer>
    </div>
    <div class="modal-buttons">
      <button (click)="close()">Close</button>
      <button class="dl" (click)="download()">
        <img src="./assets/images/downl.png" />Download
      </button>
    </div>
  </div>
</div>
