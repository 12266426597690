import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, MonoTypeOperatorFunction } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, retryWhen } from 'rxjs/operators';
import { Client, WelcomeText, Professional, FAQ, Metadata } from '../models';
import { Member } from '../../member/models';
import { AssetGrowth } from '../models/asset-growth';
import { SharedModels } from 'src/app/shared';
import { timedRetryStrategy } from './timedRetryStrategy';

@Injectable({
    providedIn: 'root'
})
export class ClientService {
    retryStrategy:MonoTypeOperatorFunction<any> = retryWhen(timedRetryStrategy({
        timeoutDuration: environment.http.timeout,
        maxRetryAttempts: environment.http.retry,
        scalingDuration: 500
    }));

    constructor(private httpClient: HttpClient) { }

    public getClientByKey(clientId : string): Observable<Client> {
        return this.httpClient.get<Client>(`${environment.apiUrl}/Fund?key=${clientId}`).pipe(this.retryStrategy);
    }

    public getClient(clientId : string): Observable<Client> {
        return this.httpClient.get<Client>(`${environment.apiUrl}/Fund`).pipe(this.retryStrategy);
    }    

    public getWelcomeText(clientId : string): Observable<WelcomeText> {
        return this.httpClient.get<WelcomeText>(`${environment.apiUrl}/Fund/Welcome`).pipe(this.retryStrategy);
    }

    public getDocuments(clientId : string): Observable<SharedModels.DocumentSnapshot[]> {
        return this.httpClient.get<SharedModels.DocumentSnapshot[]>(`${environment.apiUrl}/Fund/Documents`).pipe(this.retryStrategy);
    }    

    public getFAQs(clientId: string): Observable<FAQ[]> {
        return this.httpClient.get<FAQ[]>(`${environment.apiUrl}/Fund/FAQs`).pipe(this.retryStrategy);
    }

    public getProfessionals(clientId: string): Observable<Professional[]> {
        return this.httpClient.get<Professional[]>(`${environment.apiUrl}/Fund/Professionals`).pipe(this.retryStrategy);
    }
    
    public getAssetGrowth(clientId: string): Observable<AssetGrowth> {
        return this.httpClient.get<AssetGrowth>(`${environment.apiUrl}/Fund/Asset-Growth`).pipe(this.retryStrategy);
    }     

    public getMetadata(clientId: string): Observable<Metadata[]> {
        return this.httpClient.get<Metadata[]>(`${environment.apiUrl}/Fund/Metadata`).pipe(this.retryStrategy).pipe(map(x => x.metadata));
    }    
}