import { createFeatureSelector, createSelector } from '@ngrx/store';
import { clientFeatureKey } from './client.reducer'
import { ClientState } from './client.state';

const selectClientState = createFeatureSelector<ClientState>(clientFeatureKey);

export const getClient = createSelector(selectClientState, state => state.core);
export const getMetadata = createSelector(selectClientState, (state:ClientState, props) => state.metadata?.find(md => md.key === props.key));
export const getWelcomeText = createSelector(selectClientState, state => state.welcomeText);
export const getAssetGrowth = createSelector(selectClientState, state => state.assetGrowth);
export const getDocuments = createSelector(selectClientState, (state:ClientState, props) => state.documents?.filter(doc => doc.documentType == props.type));
export const getProfessionals = createSelector(selectClientState, state => state.professionals);
export const getFAQs = createSelector(selectClientState, state => state.faqs);