<!-- <div class="side_bar">
  <button
    *ngFor="let item of menuList; let i = index"
    mat-menu-item
    class="menuItem"
    #menuItems
    [class.hide]="item.isHidden"
    (click)="itemClick(item, i)"
  >
    {{ item.label }}
  </button>
</div> -->
<div class="side_bar">
  <button
    *ngFor="let item of menuList; let i = index"
    mat-menu-item
    class="menuItem"
    [class.selectLink]="i === idx"
    [class.hide]="item.isHidden"
    (click)="itemClick(item, i)"
  >
    {{ item.label }}
  </button>
</div>
