import { Routes } from "@angular/router";
import { AuthGuard } from '@auth0/auth0-angular';
import { AttachmentListComponent } from 'src/app/pages/components/templates/attachment-list/attachment-list.component';
import { PageContainerComponent } from "./components/containers/page-container/page-container.component";
import { DocumentViewComponent } from "./components/templates/document-list/document-view.component";
import { DrilldownDetailComponent } from "./components/templates/drilldown/drilldown-detail.component";
import { MemberPdfViewComponent } from "./components/templates/member-detail/member-pdf-view.component";
import { RetirementCalculatorResultComponent } from "./components/templates/retirement-calculator/retirement-calculator-result.component";
import { RetireePayrollHistoryPdfViewComponent } from './components/templates/retiree-payroll-history/retiree-payroll-history-pdf-view.component';

export const pagesRoutes:Routes = [
  {
    path: 'pages/:id/attachment-list',
    component: AttachmentListComponent,
    data: {
      animationState: 'AttachmentListScreen'
    }
  },
  {
    path: 'pages/:id',
    component: PageContainerComponent,  
    canActivate: [AuthGuard],
    data: {
      animationState: 'ContentScreen'
    }
  },{
    path: 'pages/:id/drilldown',
    component: DrilldownDetailComponent,
    canActivate: [AuthGuard],
    data: {
      animationState: 'DrilldownScreen'
    }
  },{
    path: 'pages/:id/document',
    component: DocumentViewComponent,
    canActivate: [AuthGuard],
    data: {
      animationState: 'DocumentScreen'
    }
  },{
    path: 'pages/:id/pdf-view',
    component: MemberPdfViewComponent,
    canActivate: [AuthGuard],
    data: {
      animationState: 'PdfViewScreen'
    }
  },{
    path: 'pages/:id/retirement',
    component: RetirementCalculatorResultComponent,
    canActivate: [AuthGuard],
    data: {
      animationState: 'CalcResultScreen'
    }
  },{
    path: 'pages/:id/payroll-history',
    component: RetireePayrollHistoryPdfViewComponent,
    canActivate: [AuthGuard],
    data: {
      animationState: 'RetireePayrollHistoryScreen'
    }
  },
];
