import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToDecimal'
})
export class ConvertToDecimalPipe implements PipeTransform {

  transform(value: string): number {
    let numVal = value.replace(/[^\.\d]/gi, "");
    return parseFloat(numVal);
  }

}
