import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { SharedModels } from 'src/app/shared';
import { DocumentType } from 'src/app/shared/models';
import { FileSignature } from 'src/app/shared/models/file-signature';
import { Client, WelcomeText, FAQ, Professional, Metadata } from '../models';
import { AssetGrowth } from '../models/asset-growth';

export const loadClient = createAction('[Client Data] Load Client', props<{ id:string }>());
export const loadClientFailure = createAction('[Client Data] Load Client Failure', props<{ id:string, error:any }>());
export const loadClientSuccess = createAction('[Client Data] Load Client Success', props<{ client:Client }>());

export const loadMetadata = createAction('[Client Data] Load Metadata', props<{ id:string }>());
export const loadMetadataFailure = createAction('[Client Data] Load Metadata Failure', props<{ id:string, error:any }>());
export const loadMetadataSuccess = createAction('[Client Data] Load Metadata Success', props<{ metadata:Metadata[] }>());

export const loadWelcomeText = createAction('[Client Data] Load Welcome Text', props<{ id:string }>());
export const loadWelcomeTextFailure = createAction('[Client Data] Load Welcome Text Failure', props<{ id:string, error:any }>());
export const loadWelcomeTextSuccess = createAction('[Client Data] Load Welcome Text Success', props<{ welcomeText:WelcomeText }>());

export const loadAssetGrowth = createAction('[Client Data] Load Asset Growth', props<{ id:string }>());
export const loadAssetGrowthFailure = createAction('[Client Data] Load Asset Growth Failure', props<{ id:string, error:any }>());
export const loadAssetGrowthSuccess = createAction('[Client Data] Load Asset Growth Success', props<{ assetGrowth:AssetGrowth }>());

export const loadProfessionals = createAction('[Client Data] Load Professionals', props<{ id:string }>());
export const loadProfessionalsFailure = createAction('[Client Data] Load Professionals Failure', props<{ id:string, error:any }>());
export const loadProfessionalsSuccess = createAction('[Client Data] Load Professionals Success', props<{ professionals:Professional[] }>());

export const loadFaqs = createAction('[Client Data] Load FAQs', props<{ id:string }>());
export const loadFaqsFailure = createAction('[Client Data] Load FAQs Failure', props<{ id:string, error:any }>());
export const loadFaqsSuccess = createAction('[Client Data] Load FAQs Success', props<{ faqs:FAQ[] }>());

export const loadDocuments = createAction('[Client Data] Load Documents', props<{ id:string }>());
export const loadDocumentsFailure = createAction('[Client Data] Load Documents Failure', props<{ id:string, error:any }>());
export const loadDocumentsSuccess = createAction('[Client Data] Load Documents Success', props<{ documents:SharedModels.DocumentSnapshot[] }>());

export const signFile = createAction('[Client Data] Sign File', props<{ fileKey: string, docType: DocumentType }>());
export const signFileSuccess = createAction('[Client Data] Sign File Success', props<{ fileKey: string, signature: FileSignature, docType: DocumentType }>());
export const signFileFailure = createAction('[Client Data] Sign File Failure', props<{ fileKey: string, error: HttpErrorResponse }>());