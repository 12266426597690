import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClientDataActions } from 'src/app/client';
import { MemberDataActions } from 'src/app/member';
import { VerifyBadgeRequest } from 'src/app/member/models/verify-badge-request';
import { MemberService } from 'src/app/member/services/member-service';
import { PagesDataActions } from 'src/app/pages';
import { Auth0Service } from 'src/app/shared/services/auth/auth0.service';
import { environment } from 'src/environments/environment';
import { AppDataActions } from '../..';
import { ClientService } from 'src/app/client/services/client-service';


@Component({
  selector: 'psrs-prompt-fund',
  templateUrl: './prompt-fund.component.html',
  styleUrls: ['./prompt-fund.component.scss']
})
export class PromptFundComponent implements OnInit {

  constructor(private authService: Auth0Service, private memberService: MemberService, private router: Router, private store: Store, private clientService: ClientService) { }
  @ViewChild("memberId") memberIdInput: TemplateRef<HTMLSelectElement>;
  promptError: string = '';
  fundLogo = `${environment.clientMediaPath}/icons/logo_64x64.png`;
  fundName: string = '';

  ngOnInit(): void {
    this.fundName = localStorage.getItem('fundName');
    if(!this.fundName) {
      this.setFundName();
    }
  }

  setFundName() {
    var fundKey = localStorage.getItem('fundKey');
    if (fundKey) {
      this.clientService.getClientByKey(fundKey).subscribe({
        next: cl => {
          this.fundName = cl.name;
          localStorage.setItem('fundName', cl.name);
        }
      })
    }
  }

  submit() {
    var sub = localStorage.getItem('sub')
    var req = {
      domain: window.location.getClientDomain(),
      adminId: sub.substr(sub.indexOf('|') + 1),
      adminName: localStorage.getItem('adminName'),
      badgeNo: this.memberIdInput['nativeElement'].value
    } as VerifyBadgeRequest;
    this.memberService.verifyMember(req).subscribe({
      next: el => {
        localStorage.setItem('memberId', el.memberId || '');
        localStorage.setItem('impersonationId', el.impersonationId || '');
        localStorage.setItem('impersonationName', el.impersonationName || '');
        var userName = el.memberName
        localStorage.setItem('memberName', userName);
        this.store.dispatch(AppDataActions.setUserName({ userName }));
        this.store.dispatch(AppDataActions.setImpersonation({ id: el.impersonationId, name: el.impersonationName }));
        this.store.dispatch(ClientDataActions.loadClient({ id: window.location.getClientDomain()}))
        this.store.dispatch(MemberDataActions.loadMember({ id: el.memberId }));
        this.store.dispatch(PagesDataActions.loadPages());
        this.store.dispatch(MemberDataActions.loadOptInMessage({clientId: el.municipalId, memberId: el.memberId}));
        this.router.navigate([""], {
          fragment: window.location.getClientDomain()
        });
        (document.querySelector('.header') as HTMLElement).style.display = '';
      }, error: err => {
        this.promptError = err.error.userMessage;
      }
    })
  }
  cancel() {
    this.authService.logout();
  }
  ngAfterViewInit() {
    (document.querySelector('.logo') as HTMLElement).style.background = `transparent url('${this.fundLogo}') 0% 0% no-repeat`;
    (document.querySelector('.header') as HTMLElement).style.display = 'none';
  }
}
