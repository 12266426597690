import { Observable, throwError, timer } from 'rxjs'
import { mergeMap, finalize, timeout } from 'rxjs/operators'

export const timedRetryStrategy =
  ({
    timeoutDuration = 2500,
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [400, 401, 402, 403, 404, 405]
  }: {
    timeoutDuration?: number
    maxRetryAttempts?: number
    scalingDuration?: number
    excludedStatusCodes?: number[]
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      timeout(timeoutDuration),
      mergeMap((error, i) => {
        const retryAttempt = i + 1
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          excludedStatusCodes.find(e => e === error.status)
        ) {
          return throwError(error)
        }

        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration)
      }),
      finalize(() => console.log('We are done!'))
    )
  }
