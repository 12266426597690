import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { DocumentType } from 'src/app/shared/models';
import * as MemberActions from './member.actions';
import { MemberDataState, MemberState } from './member.state';

export const memberFeatureKey = 'member';

export const initialState: MemberDataState = {};

const errorReducer = createReducer({ }, 

  on(MemberActions.loadMetadataFailure, (state, { itemKey, error }) => {
    return {
      itemKey,
      error
    }
  }),  
  on(MemberActions.loadMemberFailure, (state, { id, error }) => {
    return {
      action: 'loadMember',
      error: error
    }
  }),
  on(MemberActions.loadDropFailure, (state, { id, error }) => {
    return {
      action: 'loadDrop',
      error: error
    }
  }),
  on(MemberActions.loadBenefitFailure, (state, { id, error }) => {
    return {
      action: 'loadBenefit',
      error: error
    }
  }),
  on(MemberActions.loadFiscalFailure, (state, { id, error }) => {
    return {
      action: 'loadFiscal',
      error: error
    }
  }),
  on(MemberActions.loadBeneficiaryFailure, (state, { id, error }) => {
    return {
      action: 'loadBeneficiary',
      error: error
    }
  }),
  on(MemberActions.loadAddressFailure, (state, { id, error }) => {
    return {
      action: 'loadAddress',
      error: error
    }
  })
  //on(MemberActions.signFileFailure, (state, { fileKey, error }) => state)
);

const dataReducer = createReducer(initialState,

  on(MemberActions.loadMemberSuccess, (state, { member }) => {
    var coreMem = {
      ...member,
      birthday: new Date(member.birthday)
    };

    if(member.hireDate) {
      coreMem.hireDate = new Date(member.hireDate);
    }
    
    if(member.retireDate) {
      coreMem.retireDate = new Date(member.retireDate);
    }
    
    return {
      ...state,
      core: coreMem
    };
  }),
  on(MemberActions.loadMetadataSuccess, (state, { itemKey, metadata }) => {
    let updatedMetadata = {
      ...state.new
    };
    updatedMetadata[itemKey] = metadata;
    
    return {
      ...state,
      new: updatedMetadata
    };
  }),
  on(MemberActions.signAttachmentSuccess, (state, { itemKey, signature }) => {
    let result = {
      ...state,
      signature
    };
    return result;
  }),  
  on(MemberActions.loadDropSuccess, (state, { drop }) => {
    var coreDrop = {
      ...drop,
      statementDate: new Date(drop.statementDate)
    };

    if(drop.entranceDate) {
      coreDrop.entranceDate = new Date(drop.entranceDate);
    }

    if(drop.exitDate) {
      coreDrop.exitDate = new Date(drop.exitDate);
    }

    return {
      ...state,
      drop: coreDrop
    };
  }),
  on(MemberActions.loadBenefitSuccess, (state, { benefit }) => {
    var coreBenefit = {
      ...benefit,
      valuationDate: new Date(benefit.valuationDate)
    };

    return {
      ...state,
      benefit: coreBenefit
    };
  }),
  on(MemberActions.loadFiscalSuccess, (state, { fiscal }) => {
    return {
      ...state,
      fiscal: fiscal
    };
  }),
  on(MemberActions.loadBeneficiarySuccess, (state, { beneficiary }) => {
    var coreBen = {
      ...beneficiary
    };
    if(beneficiary.birthday) {
      coreBen.birthday = new Date(beneficiary.birthday);
    }

    return {
      ...state,
      beneficiary: coreBen
    };
  }),
  on(MemberActions.loadAddressSuccess, (state, { address }) => {
    return {
      ...state,
      address
    };
  }),
  on(MemberActions.signFileSuccess, (state, { signature, docType }) => {
    if(docType === DocumentType.Benefit) {
      return {
        ...state,
        benefit: {
          ...state.benefit,
          attachment: {
            ...state.benefit.attachment,
            signature: signature
          }
        }
      };
    } else if(docType === DocumentType.DROP) {
      return {
        ...state,
        drop: {
          ...state.drop,
          attachment: {
            ...state.drop.attachment,
            signature: signature
          }
        }
      };
    }
  }),
  on(MemberActions.setOptInMessage, (state) => {
    return {
      ...state,
    };
  }),

  on(MemberActions.loadOptInMessageSuccess, (state, {lstMemberNotificationOptIns}) => {
    return {
      ...state,
      lstMemberNotificationOptIns: lstMemberNotificationOptIns
    };
  }),

  on(MemberActions.generatePaymentInfoSuccess, (state, {paymentHistoryResponse}) => {
    return {
      ...state,
      paymentHistoryResponse: paymentHistoryResponse
    };
  })

);

export const memberReducers: ActionReducerMap<MemberState> = {
  data: dataReducer,
  error: errorReducer
};