import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as MetadataActions from './actions';
import { MetadataService } from '../services/metadata.service';

@Injectable()
export class MetadataEffects {

  constructor(private actions$: Actions, private metadataService:MetadataService) { }

  loadMetadata$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(MetadataActions.loadMetadata),
      mergeMap(() =>
        this.metadataService.get().pipe(
          map(metadata => MetadataActions.loadMetadataSuccess({ metadata })),
          catchError(error => of(MetadataActions.loadMetadataFailure({ error }))))
        )
    );
  });

}