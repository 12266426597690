import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggingService } from 'src/app/shared';
import { CalculatorFieldBaseComponent } from '../calculator-field-base';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'psrs-calc-list-field',
  templateUrl: './calc-list-field.component.html',
  styleUrls: ['./calc-list-field.component.scss']
})
export class CalcListFieldComponent extends CalculatorFieldBaseComponent implements OnInit {

  @Input() listOptions:any[] = [];

  isValid:Boolean = false;

  constructor(private _loggingService:LoggingService, private _modalService:NgbModal, private sanitizer:DomSanitizer) { 
    super(_loggingService, _modalService)
  }

  ngOnInit(): void {
    if(this.defaultValue) {
      let defaultItem = this.listOptions.find(x => x.code.toLowerCase() === this.defaultValue.toLowerCase() || x.id.toLowerCase() === this.defaultValue.toLowerCase());
      this.defaultValue = defaultItem.code;
      this.isValid = defaultItem != null;
      this.publishChangedStatus(this.isValid);
      this.publishChangedValue(this.defaultValue);
    }
  }

  formFieldChanged(evt): void {
    let selectedValue = evt.target.value;
    this.isValid = (selectedValue != "");

    if(this.isValid) {
      this.publishChangedValue(selectedValue);
    }
    this.publishChangedStatus(this.isValid);
  }

  safeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}